import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import { addUserOrderAsync } from "../redux/movies/movies.action";

function ProcessTokenPayment({ addUserOrderAsync }) {
  useEffect(() => {
    addUserOrderAsync();
    setTimeout(() => {
      window.location = "/playlist";
    }, 3000);
    toast.success("Movie successfully added to playlist.", {
      position: toast.POSITION.TOP_CENTER,
    });
  }, [addUserOrderAsync]);

  return (
    <>
      <h1 style={{ margin: "50px" }}>Payment Successful, Redirecting....</h1>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addUserOrderAsync: () => dispatch(addUserOrderAsync()),
});

export default connect(null, mapDispatchToProps)(ProcessTokenPayment);
