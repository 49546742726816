import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";

import { getCurrentUser as user } from "../services/authServices";
import {
  fetchMoviesAsync,
  fetchLongevityAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";
import { fetchGenresAsync } from "../redux/moviesGenre/genres.action";
import { selectLoadingStatus as movieLoading } from "../redux/movies/movies.selector";
import { selectLoadingStatus as genreLoading } from "../redux/moviesGenre/genres.selector";
import { createStructuredSelector } from "reselect";
import pusherChannel from "../services/pusher";
//import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";

import Loader from "../elements/Loader";
import Navbar from "../elements/Navbar";
import Banner from "../elements/Banner";
import MovieList from "../elements/MovieList";
import Footer from "../elements/Footer";

function Home({
  fetchMoviesAsync,
  fetchGenresAsync,
  fetchLongevityAsync,
  fetchOrderAsync,
  movieLoading,
  genreLoading,
}) {
  useEffect(() => {
    fetchMoviesAsync();
    fetchGenresAsync();
    fetchLongevityAsync();
    if (user()) fetchOrderAsync();

    const channel = pusherChannel();
    const movieEventHandler = (data) => {
      if (!isMobile) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notificationResult = new Notification(
              `${data.title} ~ ${data.message}`,
              {
                image: data.imageURL,
              }
            );
            notificationResult.onclick = (e) => {
              e.preventDefault();
              window.open(`${process.env.REACT_APP_NOTIFICATION_URL}`);
            };
          }
        });
      }
    };

    channel.bind("movie", movieEventHandler);

    //load  pages
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount && reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }

    return () => {
      channel.unbind("movie", movieEventHandler);
    };
  }, [
    fetchMoviesAsync,
    fetchGenresAsync,
    fetchLongevityAsync,
    fetchOrderAsync,
  ]);

  return (
    <>
      {(movieLoading || genreLoading) && <Loader />}
      <Navbar />
      <Banner />
      <MovieList />
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
  fetchLongevityAsync: () => dispatch(fetchLongevityAsync()),
});

const mapStateToProps = createStructuredSelector({
  genreLoading,
  movieLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
