import Pusher from "pusher-js";
import * as Sentry from "@sentry/react";

const pusherChannel = () => {
  const pusherKey = process.env.REACT_APP_PUSHER_KEY;
  if (!pusherKey) {
    Sentry.captureMessage("Pusher key is not defined", "warning | pusher.js");
    return null;
  }

  try {
    const pusher = new Pusher(pusherKey, {
      cluster: "eu",
      forceTLS: true,
    });

    const channel = pusher.subscribe("african_movies");

    channel.bind("pusher:subscription_error", (status) => {
      Sentry.captureException(
        new Error(`Pusher subscription error: ${status}`)
      );
    });

    return channel;
  } catch (error) {
    Sentry.captureException("Pusher error", error);
    return null;
  }
};

export default pusherChannel;
