import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo_test.png";
// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";

import "../stylesheets/all.css";

function AuthHeader() {
  return (
    <header>
      <nav class="navbar navbar-expand-lg navbar-dark bg-transparent">
        <div class="container">
          <Link class="navbar-brand me-5" to="/">
            {/* <ZuluLogo class="logo login-logo" alt="zulucast logo" /> */}
            <img src={Logo} width={150} height={35} />
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default AuthHeader;
