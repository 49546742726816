import React from "react";
import { NavLink } from "react-router-dom";

export default function SearchResultRow({ movie }) {
  return (
    <>
      <section className="mt-5">
        <div className="container">
          <div className="d-flex flex-wrap">
            <div className="movie-item me-0 me-md-3">
              <NavLink to={`/moviedetails/${movie?._id}`}>
                <img src={movie?.moviePictureURL} alt="" />
              </NavLink>
            </div>
            <div className="mt-3 mt-lg-0">
              <h2>{movie?.title}</h2>
              {/* <div className="mb-2">
                <h6 className="font-bold mb-0">Overview</h6>
                <p className="text-muted" style={{ width: window.innerWidth / 2 }}>
                  {movie?.description}
                </p>
              </div> */}
              <div>
                <h6 className="font-bold mb-0">Country</h6>
                <p className="text-muted">
                  <NavLink
                    to={`/moviedetails/${movie?._id}`}
                    className="text-primary"
                  >
                    {movie?.countryName} | {movie?.isocode}
                  </NavLink>
                </p>
              </div>
              <div>
                <h6 className="font-bold mb-0">
                  {movie?.actor.length > 1 ? "Actors" : "Actor"}
                </h6>
                <p className="text-muted">
                  {movie?.actor.map((a, i) => (
                    <NavLink
                      to={`/moviedetails/${movie?._id}`}
                      className="text-primary"
                    >
                      {movie?.actor[i]},{" "}
                    </NavLink>
                  ))}
                </p>
              </div>
              <div>
                <h6 className="font-bold mb-0">Language</h6>
                <p className="text-muted">
                  <NavLink
                    to={`/moviedetails/${movie?._id}`}
                    className="text-primary"
                  >
                    {movie?.language}
                  </NavLink>
                </p>
              </div>
              <div>
                <h6 className="font-bold mb-0">Genre</h6>
                <p className="text-muted">
                  <NavLink
                    to={`/moviedetails/${movie?._id}`}
                    className="text-primary"
                  >
                    {movie?.genre}
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
    </>
  );
}
