import React, { useState } from "react";
import Countdown from "react-countdown";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import Loader from "../../elements/Loader";
import WatchMovie from "../movieLink/WatchMovie";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { addExpiryDateAsync } from "../../redux/movies/movies.action";
import { selectVertualMovies } from "../../redux/movies/movies.selector";

function AllOrderItem({ order, movies, addExpiryDateAsync }) {
  const [loading, setLoading] = useState(false);

  let lengthWatched = 0;
  const movie = movies.filter((m) => m._id === order?.movieId)[0];
  const currentTime = order?.currentTime;
  const movieLength = order?.duration * 60;

  lengthWatched =
    order?.duration && currentTime < movieLength
      ? (currentTime * 85) / movieLength
      : 0;

  const handlePlayer = async (userOrder) => {
    setLoading(true);
    try {
      if (!userOrder?.startWatch) {
        await addExpiryDateAsync({ _id: userOrder?._id });
      }
      window.location = `/movie/${userOrder?.movieId}/${userOrder?._id}`;
    } catch (error) {
      console.error("Failed to handle player:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderer = ({ days, hours }) => {
    return (
      <span>
        Expires In: {days > 10 ? `${days} days` : `${days * 24 + hours} hours`}
      </span>
    );
  };

  return (
    <>
      {!movie || loading ? (
        <Loader />
      ) : (
        <li className="movie-item" style={{ marginBottom: "28px" }}>
          <span onClick={() => handlePlayer(order)}>
            <img src={order?.moviePictureURL} alt="" />
            {order?.duration && (
              <div
                className="line"
                style={{ width: `${lengthWatched}%` }}
              ></div>
            )}
          </span>
          <div className="caption">
            {!order?.startWatch && (
              <span>
                <GoDotFill color="#21baef" />{" "}
              </span>
            )}
            <span className="movie-title">{order?.title}</span> |
            <span className="movie-year">
              <Countdown
                date={
                  Date.now() +
                  new Date(order?.expiryDate).getTime() -
                  new Date().getTime()
                }
                renderer={renderer}
              />
            </span>
          </div>
          <div className="overlay">
            <div className="top">
              <Link to={`/moviedetails/${order?.movieId}`}>
                <h4 className="overlay-title">{order?.title}</h4>
                <div className="overlay-subtitle">
                  {order?.title} | {order?.duration} min
                </div>
                <div className="content">
                  <div className="overlay-description">
                    {order?.description}
                  </div>
                </div>
              </Link>
              <Link
                to={`/moviedetails/${order?.movieId}`}
                className="title-wrap"
              >
                <span className="title-head">Director:</span>
                <span className="title-name">{order?.actor}</span>
              </Link>
              <Link
                to={`/moviedetails/${order?.movieId}`}
                className="title-wrap"
              >
                <span className="title-head">Starring:</span>
                <span className="title-name">{order?.actor}</span>
              </Link>
            </div>
            <div className="bottom">
              <div className="d-grid gap-2">
                <Link
                  to={`/moviedetails/${order?.movieId}`}
                  className="btn btn-outline-primary"
                >
                  movie details
                </Link>
                <WatchMovie movie={movie} cssstyle={"btn btn-primary"} />
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
});

const mapDispatchToProps = (dispatch) => ({
  addExpiryDateAsync: (payload) => dispatch(addExpiryDateAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOrderItem);
