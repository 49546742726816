import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchMoviesAsync,
  fetchWatchlistMoviesAsync,
} from "../redux/movies/movies.action";
import {
  selectVertualMovies,
  selectWatchlistMovies,
} from "../redux/movies/movies.selector";
import { selectLoadingStatus as movieLoading } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";

import Loader from "../elements/Loader";
import AllFavSection from "../elements/AllFavSection";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";

function Watchlist({
  fetchMoviesAsync,
  fetchWatchlistMoviesAsync,
  movieLoading,
  watchlist,
  movies,
}) {
  useEffect(() => {
    //load  pages
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount && reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }

    fetchMoviesAsync();
    fetchWatchlistMoviesAsync();
  }, [fetchMoviesAsync, fetchWatchlistMoviesAsync]);

  return (
    <>
      {movieLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <h3 className="watchlist-res">My Watchlist</h3>
          <AllFavSection
            listtype={"watchlist"}
            movies={movies}
            watchlists={watchlist}
          />
          <Footer />
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchWatchlistMoviesAsync: () => dispatch(fetchWatchlistMoviesAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  watchlist: selectWatchlistMovies,
  movieLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist);
