import React from "react";
import { Link } from "react-router-dom";
import WatchMovie from "../movieLink/WatchMovie";
import { isMobile } from "react-device-detect";

function VideoItem({ movieDetails }) {
  return (
    <div className="carousel-inner" style={{ marginTop: "60px" }}>
      <div className="carousel-item active position-relative">
        <Link className="movie-link" to={`/moviedetails/${movieDetails._id}`}>
          <video autoPlay muted loop playsInline>
            <source src={movieDetails.movieTrailerURL} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </Link>
        <div className="carousel-caption bg-dark-gradient-0">
          <div className="container position-relative">
            <WatchMovie
              movie={movieDetails}
              cssstyle={"btn btn-primary px-5 float-end text-uppercase"}
            />
            {!isMobile && (
              <Link
                to={`/moviedetails/${movieDetails?._id}`}
                className="btn btn-secondary px-5 float-end text-uppercase"
                style={{ marginRight: "50px" }}
              >
                Inside The Movie
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoItem;
