import React from "react";
import { Link } from "react-router-dom";

function MovieOverview({ movie }) {
  return (
    <section className="mt-5">
      <div className="container">
        <div className="d-flex flex-wrap">
          <div className="movie-item me-0 me-md-3">
            <img src={movie.moviePictureURL} alt="" />
          </div>
          <div className="mt-3 mt-lg-0">
            <div className="mb-2">
              <h6 className="font-bold mb-0">Overview</h6>
              <p
                className="text-muted"
                style={{ width: window.innerWidth / 2 }}
              >
                {movie.description}
              </p>
            </div>
            <div>
              <h6 className="font-bold mb-0">Country</h6>
              <p className="text-muted">
                <Link
                  to={`/moviedetails/${movie?._id}`}
                  className="text-primary"
                >
                  {movie.countryName} | {movie.isocode}
                </Link>
              </p>
            </div>
            <div>
              <h6 className="font-bold mb-0">
                {movie.actor.length > 1 ? "Actors" : "Actor"}
              </h6>
              <p className="text-muted">
                {movie.actor.map((a, i) => (
                  <Link
                    to={`/moviedetails/${movie?._id}`}
                    className="text-primary"
                  >
                    {movie.actor[i]},{" "}
                  </Link>
                ))}
              </p>
            </div>
            <div>
              <h6 className="font-bold mb-0">Language</h6>
              <p className="text-muted">
                <Link
                  to={`/moviedetails/${movie?._id}`}
                  className="text-primary"
                >
                  {movie.language}
                </Link>
              </p>
            </div>
            <div>
              <h6 className="font-bold mb-0">Genre</h6>
              <p className="text-muted">
                <Link
                  to={`/moviedetails/${movie?._id}`}
                  className="text-primary"
                >
                  {movie.genre}
                </Link>
              </p>
            </div>
            {movie.rating ? (
              <div>
                <h6 className="font-bold mb-0">Rating</h6>
                <p className="text-muted m-0">{movie.rating}+</p>
                <p className="text-muted">
                  Appropriate for age {movie.rating}+
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <br />
    </section>
  );
}

export default MovieOverview;
