import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import MovieItem from "../components/movieList/MovieItem";

function MoviesMightLike({ genre, movies, actors }) {
  const genreMovies = movies.filter((m) => m.genre === genre).slice(0, 20);

  const actorMovies = movies.filter((m) =>
    m.actor.some((n) =>
      actors.map((ac) => ac.toLowerCase()).includes(n.toLowerCase())
    )
  );
  const mightLikeMovies = actorMovies.length > 0 ? actorMovies : genreMovies;

  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling

  const scrl = useRef(null);
  let scrollWidth = scrl?.current?.scrollWidth;
  let offsetWidth = scrl?.current?.offsetWidth;

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion
    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (scrl.current && scrollWidth === offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrollWidth, offsetWidth]);

  return (
    <React.Fragment>
      {mightLikeMovies.length > 0 ? (
        <section class="movies-tiles">
          <div class="container">
            <div class="head">
              <Link to={`/mightlike/${genre}`} class="title">
                You Might Also Like
              </Link>
              <Link to={`/mightlike/${genre}`} class="link">
                See all »
              </Link>
            </div>

            <div
              class="
            swiffy-slider
            slider-item-show6
            slider-nav-visible
            slider-nav-touch
            slider-nav-page
            slider-nav-noloop
            slider-nav-autopause
            slider-indicators-sm
            slider-nav-animation
          "
            >
              <ul class="slider-container" ref={scrl} onScroll={scrollCheck}>
                {mightLikeMovies.map((m, index) => (
                  <MovieItem movieDetails={m} key={index} />
                ))}
              </ul>
              {scrollX !== 0 && (
                <button
                  type="button"
                  onClick={() => slide(-160)}
                  class="slider-nav slider-nav-prev"
                ></button>
              )}
              {!scrolEnd && (
                <button
                  type="button"
                  onClick={() => slide(+160)}
                  class="slider-nav slider-nav-next"
                ></button>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </React.Fragment>
  );
}

export default MoviesMightLike;
