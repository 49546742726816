const countryActionType = {
  FETCH_COUNTRY_START: "FETCH_COUNTRY_START",
  FETCH_COUNTRY_SUCCESS: "FETCH_COUNTRY_SUCCESS",
  FETCH_COUNTRY_FAILURE: "FETCH_COUNTRY_FAILURE",
  ADD_COUNTRY_START: "ADD_COUNTRY_START",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",
  DELETE_COUNTRY_START: "DELETE_COUNTRY_START",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",
  FETCH_LANGUAGE_START: "FETCH_LANGUAGE_START",
  FETCH_LANGUAGE_SUCCESS: "FETCH_LANGUAGE_SUCCESS",
  FETCH_LANGUAGE_FAILURE: "FETCH_LANGUAGE_FAILURE",
  ADD_LANGUAGE_START: "ADD_LANGUAGE_START",
  ADD_LANGUAGE_SUCCESS: "ADD_LANGUAGE_SUCCESS",
  ADD_LANGUAGE_FAILURE: "ADD_LANGUAGE_FAILURE",
  DELETE_LANGUAGE_START: "DELETE_LANGUAGE_START",
  DELETE_LANGUAGE_SUCCESS: "DELETE_LANGUAGE_SUCCESS",
  DELETE_LANGUAGE_FAILURE: "DELETE_LANGUAGE_FAILURE",
};

export default countryActionType;
