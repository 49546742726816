import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getCurrentUser as user } from "../services/authServices";
import {
  selectLoadingStatus as loading,
  selectOrders,
} from "../redux/movies/movies.selector";

import Loader from "../elements/Loader";
import AllOrderItem from "../components/allMovies/AllOrderItem";

import "../stylesheets/allmovies.css";

function AllOrdersSection({ orders, loading }) {
  const currentUser = user();
  const userOrders = orders.filter((o) => o?.email === currentUser?.email);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {userOrders.length <= 0 ? (
            <h3 style={{ margin: "50px" }}>Your Playlist has no movies yet.</h3>
          ) : (
            <section className="movies-tiles">
              <div className="container">
                <h3 style={{ marginBottom: "20px" }}>Your playlist</h3>
                <div
                  className="
      swiffy-slider
      slider-item-show6
      slider-nav-visible
      slider-nav-touch
      slider-nav-page
      slider-nav-noloop
      slider-nav-autopause
      slider-indicators-sm
      slider-nav-animation
    "
                >
                  <ul className="AllCont">
                    {userOrders.map((m, index) => (
                      <AllOrderItem order={m} key={index} />
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  orders: selectOrders,
  loading,
});

export default connect(mapStateToProps)(AllOrdersSection);
