import React, { useState } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";

function Input({ error, icon, label, name, onChange, placeholder, type }) {
  const [showPassword, setShowPassword] = useState(false);

  const handlePswdDisplay = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div
      class={`form-group ${name === "password" || "username" ? "mt-4" : ""}`}
    >
      <label
        for={`${
          name === "password" ? "exampleInputPassword1" : "exampleInputEmail1"
        }`}
      >
        {label}{" "}
        {error && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {name !== "password_confirm" && error}
          </span>
        )}
      </label>
      <div class="input-group">
        <input
          type={showPassword ? "text" : type}
          name={name}
          onChange={onChange}
          class={`form-control ${name === "password" ? "border-end-0" : ""}`}
          //id="exampleInputEmail1"
          aria-describedby={`${
            name === "password" ? "basic-addon1" : "emailHelp"
          }`}
          placeholder={placeholder}
        />
        {icon && (
          <div class="input-group-append">
            <span class="input-group-text bg-white ">
              <span
                onClick={handlePswdDisplay}
                class="text-dark password-toggle"
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FiEye /> : <FiEyeOff />}
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Input;
