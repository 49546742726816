import React from "react";
import { useSearchParams } from "react-router-dom";

function MobileSearch() {
  const [, setSearchParams] = useSearchParams();

  const onChange = (event) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value });
  };
  return (
    <React.Fragment>
      <li className="list-group-item px-0">
        <form action="" className="search-form me-4 w-100">
          <div className="form-group active expandable-search">
            <input
              type="text"
              className="form-control"
              name="s"
              id="search"
              placeholder="Search movies"
              onChange={onChange}
              onClick={() => {
                if (window.location.pathname !== "/search-result")
                  window.location.href = `/search-result`;
              }}
            />
            <button className="btn btn-default">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </form>
      </li>
    </React.Fragment>
  );
}

export default MobileSearch;
