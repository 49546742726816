import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBannerMovie } from "../../redux/movies/movies.selector";

import SlideButton from "./SlideButton";
import SlideItem from "./SlideItem";
import sortPerCountry from "../../func/sortPerCountry";

function Slides({ bannerMovies }) {
  let movies = sortPerCountry(bannerMovies);
  return (
    <React.Fragment>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ marginTop: "50px" }}
      >
        <div className="carousel-indicators">
          {movies.map((banner, index) => (
            <SlideButton banner={banner} slideId={index} key={index} />
          ))}
        </div>

        <div className="carousel-inner">
          {movies.map((banner, index) => (
            <SlideItem banner={banner} slideId={index} key={index} />
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  bannerMovies: selectBannerMovie,
});

export default connect(mapStateToProps)(Slides);
