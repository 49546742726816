import React, { useEffect } from "react";
import authBackground from "../assets/images/auth-background.jpg";
import AuthHeader from "../elements/AuthHeader";
import AuthTerms from "../elements/AuthTerms";
import AuthTitleSide from "../elements/AuthTitleSide";
import RegistrationForm from "../elements/RegistrationForm";

const authClass = "auth-page";
const positionClass = "position-relative";

function Registration(props) {
  useEffect(() => {
    document.body.classList.add(authClass);
    document.body.classList.add(positionClass);
    document.body.style.backgroundImage = `url(${authBackground})`;
    return () => {
      document.body.classList.remove(authClass);
      document.body.classList.remove(positionClass);
      document.body.style.backgroundImage = null;
    };
  });
  return (
    <>
      <AuthHeader />
      <section className="auth-wrap position-absolute">
        <div className="row w-100 justify-content-center mt-5">
          <div className="col-md-8 col-lg-8 col-xl-7">
            <div className="auth-form-wrap p-3 p-md-5">
              <div className="row">
                <AuthTitleSide />
                <RegistrationForm />
              </div>
              <AuthTerms />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Registration;
