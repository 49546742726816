import React, { useEffect } from "react";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";
import { useSearchParams } from "react-router-dom";
import Loader from "../elements/Loader";
import SearchResultRow from "../elements/SearchResultRow";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchMoviesAsync } from "../redux/movies/movies.action";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { selectLoadingStatus as movieLoading } from "../redux/movies/movies.selector";

function SearchResult({ fetchMoviesAsync, movieLoading, movies }) {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetchMoviesAsync();
  }, [fetchMoviesAsync]);

  const searchvalue = searchParams
    .toString()
    .slice(2)
    .toLowerCase()
    .replace(/\+/g, " ");

  const actorItemResult = movies.filter((movie) =>
    movie.actor.find((element) => element.toLowerCase().includes(searchvalue))
  );

  const titleItemResult = movies.filter((movie) =>
    movie.title.toLowerCase().includes(searchvalue)
  );

  let result = actorItemResult.concat(titleItemResult);
  result = [...new Set([...actorItemResult, ...titleItemResult])];

  return (
    <>
      {movieLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar search={"search"} />
          <section
            id="section-movies-carousel-flex-header-6246182e136c2"
            className="home-section section-movies-carousel-flex-header has-bg-color dark style-2 search-res"
          >
            <div className="container">
              <div className="home-section__inner">
                <header className="home-section__flex-header">
                  <h2 className="section-title" style={{ marginTop: "20px" }}>
                    {searchParams.toString().slice(2).length > 0
                      ? `Result For :`
                      : ""}{" "}
                    {searchvalue}
                  </h2>
                </header>
                <div className="section-movies-carousel__carousel">
                  <div
                    className="movies-carousel__inner"
                    data-ride="vodi-slick-carousel"
                    data-wrap=".movies__inner"
                    data-slick='{"slidesToShow":6,"slidesToScroll":6,"dots":false,"arrows":true,"autoplay":false,"infinite":false,"responsive":[{"breakpoint":768,"settings":{"slidesToShow":2,"slidesToScroll":1}},{"breakpoint":992,"settings":{"slidesToShow":3,"slidesToScroll":1}},{"breakpoint":1200,"settings":{"slidesToShow":4,"slidesToScroll":1}}]}'
                  >
                    <div className="masvideos masvideos-movies">
                      <div className="movies columns-6">
                        <div className="movies__inner">
                          {searchParams.toString().slice(2).length <= 0 ? (
                            <h1>Type in the search box for result.</h1>
                          ) : result.length > 0 ? (
                            result.map((movie, index) => (
                              <SearchResultRow movie={movie} index={index} />
                            ))
                          ) : (
                            <h1>
                              No Result Found For :{" "}
                              <span style={{ fontStyle: "italic" }}>
                                {searchParams.toString().slice(2)}
                              </span>{" "}
                            </h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  movieLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
