import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getCurrentUser as user } from "../../services/authServices";
import constants from "../../utils/constants";

function DesktopMenu() {
  const location = useLocation();
  const currentUser = user();

  const isActive = (path) => (location.pathname === path ? "active" : "");
  const isocode = localStorage.getItem(constants.ISOCODE);
  return (
    <>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link
            className={`nav-link ${isActive(`/${isocode || "all"}`)}`}
            aria-current="page"
            to="/"
          >
            Movies
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${isActive("/genres")}`} to="/genres">
            Genres
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link ${isActive("/languages")}`}
            to="/languages"
          >
            Languages
          </Link>
        </li>
        {currentUser ? (
          <li className="nav-item">
            <Link
              className={`nav-link ${isActive("/playlist")}`}
              to="/playlist"
            >
              Playlist
            </Link>
          </li>
        ) : (
          <li className="nav-item">
            <Link className={`nav-link ${isActive("/login")}`} to="/login">
              Login
            </Link>
          </li>
        )}
      </ul>
    </>
  );
}

export default DesktopMenu;
