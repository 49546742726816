import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { fetchMoviesAsync } from "../redux/movies/movies.action";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { selectLoadingStatus as movieLoading } from "../redux/movies/movies.selector";

import SectionTitle from "../components/shared/SectionTitle";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";
import Loader from "../elements/Loader";
import AllMightLikeSection from "../elements/AllMightLikeSection";

function MightLike({ fetchMoviesAsync, movies, movieLoading }) {
  const { genre } = useParams();
  const mightLikeMovies = movies
    ?.filter((m) => m?.genre === genre)
    ?.slice(0, 20);

  useEffect(() => {
    fetchMoviesAsync();
  }, [fetchMoviesAsync]);

  return (
    <>
      {movieLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <SectionTitle title={"Movies You Might Like"} bigTitle={false} />
          {mightLikeMovies.length > 0 ? (
            <AllMightLikeSection movies={mightLikeMovies} />
          ) : (
            <h2 style={{ marginLeft: "50px" }}>No movie was found.</h2>
          )}
          <Footer />
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  movieLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MightLike);
