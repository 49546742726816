import React from "react";

import { connect } from "react-redux";
import { selectFavoriteMovies } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";
import { selectLoadingStatus as loading } from "../redux/movies/movies.selector";
import { selectUsers } from "../redux/users/users.selector";
import { getCurrentUser as user } from "../services/authServices";

import Loader from "../elements/Loader";
import * as Sentry from "@sentry/react";

const defaultProfile =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

function MovieDetailsFavourite({ loading, favorites, movie, users }) {
  const currentUser = user();
  let fav = favorites.filter((f) => f.movieId === movie._id);

  const favoritedUser = () => {
    if (!currentUser || fav.length === 0) return false;
    return fav.some((f) => f.userId === currentUser._id);
  };

  const getUserPic = (favoritemovie) => {
    try {
      if (users.length === 0) return defaultProfile;
      const theUser = users.find((u) => u._id === favoritemovie.userId);
      return theUser ? theUser.profileURL : defaultProfile;
    } catch (error) {
      Sentry.captureException(error);
      return defaultProfile;
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="mt-5">
          {fav.length > 5 ? (
            <div className="container d-flex flex-wrap align-items-center">
              {fav.slice(0, 3).map((myfav, index) => (
                <span key={index} className="favorite-image-wrap me-2">
                  <img src={getUserPic(myfav)} alt="" />
                </span>
              ))}
              <span className="text-muted mt-1 mt-md-0">
                and <span className="text-primary">{fav.length - 3} </span>
                people favourited this movie.
              </span>
            </div>
          ) : (
            <>
              {favoritedUser() ? (
                <span
                  className="text-muted mt-1 mt-md-0"
                  style={{ marginLeft: "20px" }}
                >
                  {" "}
                  You and{" "}
                  <span className="text-primary">{fav.length - 1} </span>
                  other people favourited this movie.
                </span>
              ) : (
                <span
                  className="text-muted mt-1 mt-md-0"
                  style={{ marginLeft: "20px" }}
                >
                  <span className="text-primary">{fav.length} </span>
                  people favourited this movie.
                </span>
              )}
            </>
          )}
        </section>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  favorites: selectFavoriteMovies,
  users: selectUsers,
  loading,
});

export default connect(mapStateToProps)(MovieDetailsFavourite);
