export const constants = {
  INVALID_TOKEN: "Invalid Token",
  TOKEN: "token",

  ISOCODE: "isocode",

  SLIDESASBANNER: "s",
  VIDEOASBANNER: "v",

  PAYMENT_FAILED: "cancelled",
  PAYMENT_SUCCESS: "success",

  FLAGURL: "flagurl",

  GHS: "GHS",
  USD: "USD",
  NGN: "NGN",

  GHANA: "ghana",
  NIGERIA: "nigeria",
};

export default constants;
