import React, { useEffect } from "react";

import authBackground from "../assets/images/auth-background.jpg";
// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";
import AuthHeader from "../elements/AuthHeader";
import AuthTerms from "../elements/AuthTerms";
import { Link } from "react-router-dom";

const authClass = "auth-page";
const positionClass = "position-relative";

function ConfirmMail() {
  useEffect(() => {
    document.body.classList.add(authClass);
    document.body.classList.add(positionClass);
    document.body.style.backgroundImage = `url(${authBackground})`;
    return () => {
      document.body.classList.remove(authClass);
      document.body.classList.remove(positionClass);
      document.body.style.backgroundImage = null;
    };
  });

  return (
    <React.Fragment>
      <AuthHeader />
      <section class="auth-wrap position-absolute">
        <div class="row w-100 justify-content-center mt-5">
          <div class="col-md-8 col-lg-8 col-xl-7">
            <div class="auth-form-wrap p-3 p-md-5">
              <div class="row">
                <div>
                  <div className="c-outer-div">
                    {/* <ZuluLogo width="60px" height="12px" /> */}{" "}
                    <h2>African Movies</h2>
                    <span style={{ fontSize: "10px", marginLeft: "20px" }}>
                      Mail Confirmation | African Movies
                    </span>
                  </div>
                  <div className="c-inner-div">
                    {/* <ZuluLogo width="100px" height="40px" /> */}{" "}
                    <h2>African Movies</h2>
                    <br />
                    <hr />
                    <h3>Reset Password</h3>
                    <br />
                    <p style={{ fontSize: "13px" }}>
                      Check your inbox for the next steps. If you don't receive
                      an email, and it's not in your spam folder this could mean
                      you signed up with a different address.
                    </p>
                    <br />
                    <Link style={{ color: "#21baef" }} to="/login">
                      Login
                    </Link>{" "}
                    Or{" "}
                    <Link style={{ color: "#21baef" }} to="/registration">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
              <AuthTerms />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ConfirmMail;
