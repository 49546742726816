import React from "react";
import { isMobile } from "react-device-detect";

// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";
import about from "../assets/images/about.png";
import map from "../assets/images/map.png";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";

import "../stylesheets/about.css";

function AboutUs() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="about-container">
        <img src={about} width={window.innerWidth} alt="" />
        <div className="myabout">
          <div class="about-text">
            On Africanmovies.com, stream African movies, on any of your devices.
          </div>
          {!isMobile && (
            <div class="about-small">
              Enjoy the best of African movies from Anywhere.
            </div>
          )}
        </div>
      </div>
      <div>
        {/* <ZuluLogo class="about-logo" /> */} African Movies
        <h4 class="about-perspective">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </h4>
      </div>
      <div className="about-container">
        <h6>Our Locations</h6>
        <img src={map} width={window.innerWidth} alt="" />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default AboutUs;
