const movieActionTypes = {
  FETCH_MOVIES_START: "FETCH_MOVIES_START",
  FETCH_MOVIES_SUCCESS: "FETCH_MOVIES_SUCCESS",
  FETCH_MOVIES_FAILURE: "FETCH_MOVIES_FAILURE",
  FETCH_FAV_MOVIES_START: "FETCH_FAV_MOVIES_START",
  FETCH_FAV_MOVIES_SUCCESS: "FETCH_FAV_MOVIES_SUCCESS",
  FETCH_FAV_MOVIES_FAILURE: "FETCH_FAV_MOVIES_FAILURE",
  FETCH_WATCHLIST_MOVIES_START: "FETCH_WATCHLIST_MOVIES_START",
  FETCH_WATCHLIST_MOVIES_SUCCESS: "FETCH_WATCHLIST_MOVIES_SUCCESS",
  FETCH_WATCHLIST_MOVIES_FAILURE: "FETCH_WATCHLIST_MOVIES_FAILURE",
  ADD_FAV_MOVIES_START: "ADD_FAV_MOVIES_START",
  ADD_FAV_MOVIES_SUCCESS: "ADD_FAV_MOVIES_SUCCESS",
  ADD_FAV_MOVIES_FAILURE: "ADD_FAV_MOVIES_FAILURE",
  ADD_WATCHLIST_MOVIES_START: "ADD_WATCHLIST_MOVIES_START",
  ADD_WATCHLIST_MOVIES_SUCCESS: "ADD_WATCHLIST_MOVIES_SUCCESS",
  ADD_WATCHLIST_MOVIES_FAILURE: "ADD_WATCHLIST_MOVIES_FAILURE",
  ADD_TO_ORDER_START: "ADD_TO_ORDER_START",
  ADD_TO_ORDER_SUCCESS: "ADD_TO_ORDER_SUCCESS",
  ADD_TO_ORDER_FAILURE: "ADD_TO_ORDER_FAILURE",
  ADD_TO_PRE_ORDER_START: "ADD_TO_PRE_ORDER_START",
  ADD_TO_PRE_ORDER_SUCCESS: "ADD_TO_PRE_ORDER_SUCCESS",
  ADD_TO_PRE_ORDER_FAILURE: "ADD_TO_PRE_ORDER_FAILURE",
  FETCH_ORDERS_START: "FETCH_ORDERS_START",
  FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
  FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE",
  ADD_EXPIRY_DATE_START: "ADD_EXPIRY_DATE_START",
  ADD_EXPIRY_DATE_SUCCESS: "ADD_EXPIRY_DATE_SUCCESS",
  ADD_EXPIRY_DATE_FAILURE: "ADD_EXPIRY_DATE_FAILURE",
  FETCH_LONGEVITY_START: "FETCH_LONGEVITY_START",
  FETCH_LONGEVITY_SUCCESS: "FETCH_LONGEVITY_SUCCESS",
  FETCH_LONGEVITY_FAILURE: "FETCH_LONGEVITY_FAILURE",
  DELETE_PRE_ORDER_START: "DELETE_PRE_ORDER_START",
  DELETE_PRE_ORDER_SUCCESS: "DELETE_PRE_ORDER_SUCCESS",
  DELETE_PRE_ORDER_FAILURE: "DELETE_PRE_ORDER_FAILURE",
  ADD_TO_USER_ORDER_START: "ADD_TO_USER_ORDER_START",
  ADD_TO_USER_ORDER_SUCCESS: "ADD_TO_USER_ORDER_SUCCESS",
  ADD_TO_USER_ORDER_FAILURE: "ADD_TO_USER_ORDER_FAILURE",

  REMOVE_USER_ORDER_START: "REMOVE_USER_ORDER_START",
  REMOVE_USER_ORDER_SUCCESS: "REMOVE_USER_ORDER_SUCCESS",
  REMOVE_USER_ORDER_FAILURE: "REMOVE_USER_ORDER_FAILURE",
};

export default movieActionTypes;
