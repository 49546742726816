import React from "react";
import { Link } from "react-router-dom";

function WatchTrailer({ movie }) {
  return (
    <Link
      to={`/trailer/${movie?._id}`}
      className="btn btn-secondary px-5 float-end text-uppercase"
    >
      Watch Trailer
    </Link>
  );
}

export default WatchTrailer;
