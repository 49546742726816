import React from "react";

import "../../stylesheets/all.css";

function SectionTitle({ title, bigTitle = true }) {
  return (
    <section class="movies-titles playlisttile">
      <div class="container">
        <div class="head">
          <br />
          {bigTitle ? (
            <h2 style={{ color: "#fff" }} class="title">
              {title}
            </h2>
          ) : (
            <h4 style={{ color: "#fff" }} class="title">
              {title}
            </h4>
          )}
        </div>
      </div>
      <br />
    </section>
  );
}

export default SectionTitle;
