import usersActionTypes from "./users.types";
import { getUsers, verifyToken } from "../../services/usersService";
import { toast } from "react-toastify";
import {
  login,
  register,
  resetPassword,
  updateUsername,
  forgotPasswordMail,
  modifyPassword,
} from "../../services/authServices";
import constants from "../../utils/constants";

export const fetchUsersStart = () => ({
  type: usersActionTypes.USERS_FETCH_START,
});

export const fetchUsersSuccess = (users) => ({
  type: usersActionTypes.USERS_FETCH_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = () => ({
  type: usersActionTypes.USERS_FETCH_FAILURE,
});

export const fetchUsersAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersStart());
      const { data: users } = await getUsers();
      dispatch(fetchUsersSuccess(users));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchUsersFailure());
        toast.error("An error occurred during your request", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const loginUserStart = () => ({
  type: usersActionTypes.LOGIN_USER_START,
});

export const loginUserSuccess = () => ({
  type: usersActionTypes.LOGIN_USER_SUCCESS,
});

export const loginUserFailure = () => ({
  type: usersActionTypes.LOGIN_USER_FAILURE,
});

export const loginUserAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserStart());
      const { data } = await login(user_details);
      localStorage.setItem("token", data?.token);
      toast.success("Successfully logged in.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(loginUserSuccess());
      if ("from" in localStorage) {
        const id = localStorage.getItem("from");
        localStorage.removeItem("from");
        setTimeout(() => {
          window.location = `/moviedetails/${id}`;
        }, 3000);
      } else {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(loginUserFailure());
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const registerUserStart = () => ({
  type: usersActionTypes.REGISTER_USER_START,
});

export const registerUserSuccess = () => ({
  type: usersActionTypes.REGISTER_USER_SUCCESS,
});

export const registerUserFailure = () => ({
  type: usersActionTypes.REGISTER_USER_FAILURE,
});

export const registerUserAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(registerUserStart());
      const { data } = await register(user_details);
      localStorage.setItem("token", data?.token);
      toast.success("Successfully registered.", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(registerUserSuccess());
      if ("from" in localStorage) {
        const id = localStorage.getItem("from");
        window.location = `/moviedetails/${id}`;
        localStorage.removeItem("from");
      } else {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(registerUserFailure());
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const passwordResetStart = () => ({
  type: usersActionTypes.PASSWORD_RESET_START,
});

export const passwordResetSuccess = () => ({
  type: usersActionTypes.PASSWORD_RESET_SUCCESS,
});

export const passwordResetFailure = () => ({
  type: usersActionTypes.PASSWORD_RESET_FAILURE,
});

export const passwordResetAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(passwordResetStart());
      await resetPassword(user_details);
      dispatch(passwordResetSuccess());
      toast.success("Successfull Reset, now you can login.", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/login";
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(passwordResetFailure());
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const passwordModifyStart = () => ({
  type: usersActionTypes.PASSWORD_MODIFY_START,
});

export const passwordModifySuccess = () => ({
  type: usersActionTypes.PASSWORD_MODIFY_SUCCESS,
});

export const passwordModifyFailure = () => ({
  type: usersActionTypes.PASSWORD_MODIFY_FAILURE,
});

export const passwordModifyAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(passwordModifyStart());
      await modifyPassword(user_details);
      dispatch(passwordModifySuccess());
      toast.success("Successfull Password Reset, now login...", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("token");
      setTimeout(() => {
        window.location = "/login";
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(passwordModifyFailure());
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const usernameUpdateStart = () => ({
  type: usersActionTypes.USERNAME_RESET_START,
});

export const usernameUpdateSuccess = () => ({
  type: usersActionTypes.USERNAME_RESET_SUCCESS,
});

export const usernameUpdateFailure = () => ({
  type: usersActionTypes.USERNAME_RESET_FAILURE,
});

export const usernameUpdateAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(usernameUpdateStart());
      await updateUsername(user_details);
      dispatch(usernameUpdateSuccess());
      localStorage.setItem("username", user_details.username);
      toast.success("Username Successfuly updated...", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(usernameUpdateFailure());
        toast.error("An error occurred during your request", {
          position: toast.POSITION.TOP_CENTER,
        });
        alert("An error occurred during your request");
      }
    }
  };
};

export const forgotPasswordMaileStart = () => ({
  type: usersActionTypes.FORGOT_PASS_MAIL_START,
});

export const forgotPasswordMaileSuccess = () => ({
  type: usersActionTypes.FORGOT_PASS_MAIL_SUCCESS,
});

export const forgotPasswordMaileFailure = () => ({
  type: usersActionTypes.FORGOT_PASS_MAIL_FAILURE,
});

export const forgotPasswordMaileAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(forgotPasswordMaileStart());
      await forgotPasswordMail(user_details);
      dispatch(forgotPasswordMaileSuccess());
      window.location = "/confirmmail";
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(forgotPasswordMaileFailure()); //        toast.error("An error occurred during your request");
        toast.error(ex.response.data || "error occured", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
};

export const verifyTokenStart = () => ({
  type: usersActionTypes.VERIFY_TOKEN_START,
});

export const verifyTokenSuccess = () => ({
  type: usersActionTypes.VERIFY_TOKEN_SUCCESS,
});

export const verifyTokenFailure = () => ({
  type: usersActionTypes.VERIFY_TOKEN_FAILURE,
});

export const verifyTokenAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(verifyTokenStart());
      const { data } = await verifyToken({
        token: localStorage.getItem(constants.TOKEN),
      });
      dispatch(verifyTokenSuccess());
      if (data === constants.INVALID_TOKEN) {
        localStorage.removeItem(constants.TOKEN);
        window.location = "/";
      }
    } catch (ex) {
      dispatch(verifyTokenFailure());
      toast.error(ex.response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
};
