import { createSelector } from "reselect";
import { getCurrentUser } from "../../services/authServices";

const selectMovies = (state) => state.movies;

export const selectLoadingStatus = createSelector(
  [selectMovies],
  (movies) => movies.isLoading
);

export const selectVertualMovies = createSelector(
  [selectMovies],
  (movies) => movies.vertualMovies
);

export const selectAllMovies = createSelector([selectMovies], (movies) =>
  movies.allMovies.filter((m) => !m.isBanner)
);

export const selectFavoriteMovies = createSelector(
  [selectMovies],
  (movies) => movies.favoriteMovies
);

export const selectWatchlistMovies = createSelector(
  [selectMovies],
  (movies) => movies.watchlistMovies
);

export const selectBannerMovie = createSelector([selectMovies], (movies) =>
  movies.allMovies.filter((m) => m.isBanner)
);

export const selectLongevity = createSelector(
  [selectMovies],
  (movies) => movies.longevity
);

export const selectOrders = createSelector([selectMovies], (movies) =>
  movies.orders
    .filter(
      (order) => new Date(order.expiryDate).getTime() > new Date().getTime()
    )
    .filter((o) => new Date(o.movieExpiresOn).getTime() > new Date().getTime())
);

export const selectUserOrders = createSelector([selectMovies], (movies) =>
  movies.orders.filter(
    (order) =>
      new Date(order.expiryDate).getTime() > new Date().getTime() &&
      new Date(order.movieExpiresOn).getTime() > new Date().getTime() &&
      order.userId === getCurrentUser()._id
  )
);
