import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";
import { getCardToken } from "../services/paymentServices";

export default function PaymentDetails() {
  const [card, setCard] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await getCardToken();
      setCard(data);
    })();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container profile">
        <div className="row">
          {!isMobile && <div className="col"></div>}
          <div className={`${isMobile ? "" : "col-8"}`}>
            <h3>Payment Details</h3>
            <br />
            <div className="innercontainer">
              <h3 className="title">Payment Card Information</h3>
              {/* profile row ends */}
              <hr className="hrcolor" />

              {/* profile username */}

              <div className="row">
                <div className="col hrcolor">
                  <h6>Country</h6>
                </div>
                <div className="col hrcolor">
                  {" "}
                  <h5>{card?.tokenPayload?.country ?? "N/A"}</h5>{" "}
                </div>
                {/* <div className="col">3 of 3</div> */}
              </div>
              <hr className="hrcolor" />

              {/* profile username ends */}

              {/* profile email starts */}
              <div className="row">
                <div className="col hrcolor">
                  <h6>Card Number</h6>
                </div>
                <div className="col hrcolor">
                  <h5>
                    {card
                      ? "************" + card.tokenPayload.last4Digits
                      : "N/A"}
                  </h5>
                </div>
                {/* <div className="col">3 of 3</div> */}
              </div>
              <hr className="hrcolor" />
              {/* profile email ends */}

              {/* password starts */}

              <div className="row">
                <div className="col hrcolor">
                  <h6>Expiry Date</h6>
                </div>
                <div className="col hrcolor">
                  {" "}
                  <h5>{card?.tokenPayload?.expiry ?? "N/A"}</h5>{" "}
                </div>
              </div>
              <hr className="hrcolor" />
              {/* password ends */}

              <div className="row">
                <div className="col hrcolor">
                  <h6>Card Type</h6>
                </div>
                <div className="col hrcolor">
                  <h5>{card?.tokenPayload?.cardType ?? "N/A"}</h5>{" "}
                </div>
              </div>

              <hr className="hrcolor" />
              {/* about me ends */}

              {/* button ends */}
            </div>
          </div>
          {!isMobile && <div className="col"></div>}
        </div>
      </div>
      <Footer />
    </>
  );
}
