import React from "react";
import { Link } from "react-router-dom";
import Favorite from "../components/movieDetails/Favorite";
import Watchlist from "../components/movieDetails/Watchlist";
import FacebookShare from "../components/movieDetails/FacebookShare";
import TwitterShare from "../components/movieDetails/TwitterShare";
import WatchMovie from "../components/movieLink/WatchMovie";

function MobileMovieDetailsBanner({ movie }) {
  return (
    <React.Fragment>
      {/* <!-- banner content mobile --> */}
      <div className="banner-content-mobile bg-dark-gradient-0 d-block d-md-none">
        <div className="container position-relative">
          <div className="movie-details text-start text-white">
            <h1 className="banner-movie-title">{movie?.title || "Untitled"}</h1>
            <div>
              <span>
                <Link
                  to="/"
                  className="text-white text-decoration-none font-bold"
                >
                  {movie?.title || "Untitled"}
                </Link>
              </span>
              |
              <span>
                <Link
                  to="/"
                  className="font-bold text-decoration-none text-white"
                >
                  {movie?.releaseYear || "N/A"}
                </Link>
              </span>
              <span>
                <Link
                  to="/"
                  className="font-light text-decoration-none text-white-50"
                >
                  | {movie?.duration || "N/A"}
                </Link>
              </span>
              <span>
                <Link
                  to="/"
                  className="font-light text-decoration-none text-muted hd-logo"
                >
                  <img src="/assets/images/hd.png" className="" alt="" />
                </Link>
              </span>
              <span className="rating">{movie?.rating || "N/A"}+</span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 text-center mb-3">
              <Favorite movie={movie} />
              <Watchlist movie={movie} />
              <TwitterShare
                title={`Watch ${movie.title} on ZuluCast.`}
                url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie._id}`}
              />
              <FacebookShare
                title={`Watch ${movie.title} on ZuluCast.`}
                url={`${process.env.REACT_APP_NOTIFICATION_URL}/moviedetails/${movie._id}`}
              />
            </div>
            <div className="col-md-6 d-grid gap-2">
              <WatchMovie
                movie={movie}
                cssstyle={"btn btn-primary px-5 text-uppercase"}
              />
              <Link
                to={`/trailer/${movie?._id}`}
                className="btn btn-secondary px-5 text-uppercase"
              >
                Watch Trailer
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MobileMovieDetailsBanner;
