import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Loader from "../../elements/Loader";

import { selectBannerMovie } from "../../redux/movies/movies.selector";
import VideoItem from "./VideoItem";

function Video(props) {
  const { bannerMovies } = props;
  const max = bannerMovies.length;
  const randomBannerIndex = Math.floor(Math.random() * max);
  return (
    <React.Fragment>
      {!bannerMovies[randomBannerIndex] ? (
        <Loader />
      ) : (
        <div
          id="videoCarousel"
          className="carousel slide video-carousel"
          data-bs-ride="carousel"
        >
          <VideoItem movieDetails={bannerMovies[randomBannerIndex]} />
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  bannerMovies: selectBannerMovie,
});

export default connect(mapStateToProps)(Video);
