import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import { RxCross2 } from "react-icons/rx";
import { PiCheckBold } from "react-icons/pi";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { registerUserAsync } from "../redux/users/users.action";
import { selectLoadingStatus as loading } from "../redux/users/users.selector";

import Form from "../components/authentication/Form";
import Loader from "../elements/Loader";
// import GoogleSignIn from "../components/social.login/googleSignIn";
// import FacebookSignIn from "../components/social.login/facebookSignIn";

const PasswordCheck = (verified) =>
  verified ? <PiCheckBold color="green" /> : <RxCross2 color="red" />;

class RegistrationForm extends Form {
  state = {
    data: {
      email: "",
      username: "",
      password: "",
    },
    passwordComplexity: {},
    error: {},
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
    username: Joi.string().min(3).max(16).required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    const { registerUserAsync } = this.props;
    registerUserAsync(this.state.data);
    this.setState({
      data: {
        email: "",
        username: "",
        password: "",
      },
    });
  };

  componentDidMount() {
    document.title = "African Movies | Registration";
  }

  render() {
    const { loading } = this.props;
    const { passwordComplexity } = this.state;
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="col-lg-6">
            <h3 className="font-bold mb-5">Sign Up</h3>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("text", "email", "Enter your mail", "Email")}
              {this.renderInput(
                "text",
                "username",
                "Enter your username",
                "Username"
              )}
              {this.renderInput(
                "password",
                "password",
                "Create your password",
                "Password",
                "icon"
              )}
              <div style={{ marginTop: "20px" }}>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyLength)} The
                  password must be at least 8 characters long.
                </div>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyDigit)} It
                  must contain at least one digit (0-9).
                </div>
                <div class="text-muted" style={{ textAlignLast: "center" }}>
                  {PasswordCheck(passwordComplexity?.passwordVerifyCase)} It
                  must include a combination of uppercase and lowercase letters
                  (A-Z and a-z).
                </div>
              </div>
              {this.renderButton("Create Account")}
            </form>

            <div className="mt-4 d-flex justify-content-between flex-wrap">
              <span className="text-center">
                Already Have An Account{" "}
                <Link to="/login" className="text-secondary">
                  Sign In.
                </Link>
              </span>
              {/* <span className="">
                <Link to="">Forgotten password?</Link>
              </span> */}
            </div>

            {/* <div className="row mt-4">
              <div className="col d-flex pe-0">
                <div className="faint-line my-auto"></div>
              </div>
              <div className="col text-muted text-center d-flex justify-content-center">
                or continue with
              </div>
              <div className="col d-flex ps-0">
                <div className="faint-line my-auto"></div>
              </div>
            </div> */}
            <div className="d-flex justify-content-center mt-4">
              {/* <GoogleSignIn />
              <FacebookSignIn /> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  registerUserAsync: (user) => dispatch(registerUserAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
