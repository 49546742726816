import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAllGenres } from "../../redux/moviesGenre/genres.selector";
import MovieGenreItem from "./MovieGenreItem";

function MovieGenreList({ genres }) {
  return (
    <>
      {genres.map((genre, index) => (
        <MovieGenreItem genre={genre} key={index} />
      ))}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  genres: selectAllGenres,
});

export default connect(mapStateToProps)(MovieGenreList);
