const usersActionTypes = {
  USERS_FETCH_START: "USERS_FETCH_START",
  USERS_FETCH_SUCCESS: "USERS_FETCH_SUCCESS",
  USERS_FETCH_FAILURE: "USERS_FETCH_FAILURE",
  USER_FETCH_START: "USER_FETCH_START",
  USER_FETCH_SUCCESS: "USER_FETCH_SUCCESS",
  USER_FETCH_FAILURE: "USER_FETCH_FAILURE",
  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  REGISTER_USER_START: "REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
  PASSWORD_RESET_START: "PASSWORD_RESET_START",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  PASSWORD_MODIFY_START: "PASSWORD_MODIFY_START",
  PASSWORD_MODIFY_SUCCESS: "PASSWORD_MODIFY_SUCCESS",
  PASSWORD_MODIFY_FAILURE: "PASSWORD_MODIFY_FAILURE",
  USERNAME_RESET_START: "USERNAME_RESET_START",
  USERNAME_RESET_SUCCESS: "USERNAME_RESET_SUCCESS",
  USERNAME_RESET_FAILURE: "USERNAME_RESET_START",
  FORGOT_PASS_MAIL_START: "FORGOT_PASS_MAIL_START",
  FORGOT_PASS_MAIL_SUCCESS: "FORGOT_PASS_MAIL_SUCCESS",
  FORGOT_PASS_MAIL_FAILURE: "FORGOT_PASS_MAIL_FAILURE",
  VERIFY_TOKEN_START: "VERIFY_TOKEN_START",
  VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_FAILURE: "VERIFY_TOKEN_FAILURE",
};

export default usersActionTypes;
