import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchOrderAsync,
  fetchMoviesAsync,
} from "../redux/movies/movies.action";

import SectionTitle from "../components/shared/SectionTitle";
import Navbar from "../elements/Navbar";
import Footer from "../elements/Footer";
import AllOrdersSection from "../elements/AllOrdersSection";

function Playlist({ fetchOrderAsync, fetchMoviesAsync }) {
  useEffect(() => {
    fetchOrderAsync();
    fetchMoviesAsync();
    //load page
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount && reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, [fetchOrderAsync, fetchMoviesAsync]);
  return (
    <>
      <Navbar />
      <SectionTitle title={""} bigTitle={false} />
      <AllOrdersSection />
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

export default connect(null, mapDispatchToProps)(Playlist);
