const cartActionTypes = {
  ADD_TO_CART: "ADD_TO_CART",
  WATCH_LATER: "WATCH_LATER",
  FETCH_CART_ITEMS: "FETCH_CART_ITEMS",
  FETCH_WATCH_LATER: "FETCH_WATCH_LATER",
  CLEAR_ITEM: "CLEAR_ITEM",
  CLEAR_WATCH_LATER: "CLEAR_WATCH_LATER",
};

export default cartActionTypes;
