import React, { useState } from "react";
import { BsSuitHeartFill, BsSuitHeart } from "react-icons/bs";
import * as Sentry from "@sentry/react";

import { connect } from "react-redux";
import { selectFavoriteMovies } from "../../redux/movies/movies.selector";
import { addFavoriteMovieAsync } from "../../redux/movies/movies.action";
import { createStructuredSelector } from "reselect";
import { selectLoadingStatus as loading } from "../../redux/movies/movies.selector";

import Loader from "../../elements/Loader";
import { getCurrentUser as user } from "../../services/authServices";

const hoverColor = "#21baef";
const white = "white";

function Favorite({ favorites, addFavoriteMovieAsync, movie, loading }) {
  const currentUser = user();
  const [color, setColor] = useState(white);

  let fav = favorites.filter((f) => f.movieId === movie._id);

  const { _id, title } = movie || {};

  const handleClick = () => {
    try {
      if (!currentUser) {
        localStorage.setItem("from", _id);
        window.location = "/login";
        return;
      }
      addFavoriteMovieAsync({
        movieId: _id,
        movieTitle: title,
      });
      window.location.reload();
    } catch (error) {
      Sentry.captureException("Favorite.jsx ", error);
    }
  };

  const isFavourated = () => {
    if (!currentUser) return false;
    const userFavorites = fav.filter((f) => f.userId === currentUser._id);
    return userFavorites.length > 0;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <span
          className="favorite-icon-wrap me-3"
          title={isFavourated() ? "Remove from favorite" : "Add to favorites"}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          {isFavourated() ? (
            <BsSuitHeartFill size={30} color={hoverColor} />
          ) : (
            <BsSuitHeart
              size={30}
              color={color}
              onMouseEnter={() => setColor(hoverColor)}
              onMouseLeave={() => setColor(white)}
            />
          )}
        </span>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  favorites: selectFavoriteMovies,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  addFavoriteMovieAsync: (payload) => dispatch(addFavoriteMovieAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
