import React, { useEffect } from "react";
import Footer from "../elements/Footer";
import Loader from "../elements/Loader";
import GenreSection from "../elements/GenreSection";
import Navbar from "../elements/Navbar";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchMoviesAsync } from "../redux/movies/movies.action";
import { fetchGenresAsync } from "../redux/moviesGenre/genres.action";
import { selectLoadingStatus as loading } from "../redux/moviesGenre/genres.selector";

import "../stylesheets/genres.css";

function Genres({ fetchGenresAsync, fetchMoviesAsync, loading }) {
  useEffect(() => {
    fetchGenresAsync();
    fetchMoviesAsync();
  }, [fetchGenresAsync, fetchMoviesAsync]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <GenreSection />
          <Footer />
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Genres);
