import React from "react";
import { useSearchParams, useLocation } from "react-router-dom";

const searchId = "expandable-search";

function DesktopSearch({ search }) {
  const [, setSearchParams] = useSearchParams();

  const onChange = (event) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value });
  };

  const location = useLocation();
  const isSearchPathname = true
    ? location.pathname === "/search-result"
    : false;

  const activateSearch = () =>
    document.getElementById(searchId).classList.add("active");

  const hideSearch = () =>
    document.getElementById(searchId).classList.remove("active");

  return (
    <React.Fragment>
      {/* <!-- search --> */}
      <form action="" className="search-form me-4">
        <div
          id="expandable-search"
          className={
            search === "search"
              ? "expandable-search"
              : "form-group expandable-search"
          }
          on="true"
          onMouseEnter={() => activateSearch()}
          onMouseLeave={() => hideSearch()}
        >
          <input
            type="text"
            className="form-control"
            name="s"
            id="search"
            autoFocus={isSearchPathname}
            placeholder="Search movies"
            onChange={onChange}
            onClick={() => {
              if (window.location.pathname !== "/search-result")
                window.location.href = `/search-result`;
            }}
          />
          {search === "search" ? null : (
            <button className="btn btn-default">
              <i className="fas fa-search"></i>
            </button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}

export default DesktopSearch;
