import React from "react";
import AllMovieItem from "../components/allMovies/AllMovieItem";
import { getCurrentUser as user } from "../services/authServices";

import "../stylesheets/allmovies.css";

function AllFavSection({ listtype, movies, favorites }) {
  const currentUser = user();
  const filteredMovies = movies.filter((movie) =>
    favorites?.some(
      (listItem) =>
        listItem.userMail === currentUser.email &&
        listItem.movieId === movie._id
    )
  );

  return (
    <React.Fragment>
      <section className="movies-tiles">
        <div className="container">
          <div
            className="
      swiffy-slider
      slider-item-show6
      slider-nav-visible
      slider-nav-touch
      slider-nav-page
      slider-nav-noloop
      slider-nav-autopause
      slider-indicators-sm
      slider-nav-animation
    "
          >
            <ul className="AllCont">
              {filteredMovies.length > 0 ? (
                filteredMovies.map((m, index) => (
                  <AllMovieItem movie={m} key={index} />
                ))
              ) : (
                <h2>
                  No movies{" "}
                  {`${
                    listtype === "watchlist"
                      ? "found in your watchlist."
                      : "favorited yet."
                  }`}{" "}
                  .
                </h2>
              )}
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AllFavSection;
