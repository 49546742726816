import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { forgotPasswordMaileAsync } from "../redux/users/users.action";
import { selectLoadingStatus as loading } from "./../redux/users/users.selector";

import Form from "../components/authentication/Form";
import Loader from "../elements/Loader";

class ForgotPassAuthForm extends Form {
  state = {
    data: {
      email: "",
    },
    error: {},
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
  };

  doSubmit = async () => {
    const { forgotPasswordMaileAsync } = this.props;
    forgotPasswordMaileAsync(this.state.data);
    this.setState({
      data: {
        email: "",
      },
    });
  };

  componentDidMount() {
    document.title = "African Movies | Forgotten Password";
  }

  render() {
    const { loading } = this.props;
    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div class="col-lg-6">
            <h3 class="font-bold mb-5">Recover Password</h3>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("text", "email", "Enter your mail", "Email")}
              {this.renderButton("Recover My Password")}
            </form>

            <div class="mt-4 d-flex justify-content-between flex-wrap">
              <span class="text-center">
                Or Create a{" "}
                <Link to="/registration" class="text-secondary">
                  new account.
                </Link>
              </span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPasswordMaileAsync: (user) => dispatch(forgotPasswordMaileAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassAuthForm);
