import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAllLanguages } from "../../redux/country/country.selector";

import MovieLanguageItem from "./MovieLanguageItem";

function MovieLanguageList({ languages }) {
  return (
    <>
      {languages.map((language, index) => (
        <MovieLanguageItem language={language} key={index} />
      ))}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  languages: selectAllLanguages,
});

export default connect(mapStateToProps)(MovieLanguageList);
