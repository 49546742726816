import React from "react";
import { Link } from "react-router-dom";
import { getCurrentUser as user } from "../../services/authServices";

function MobileMenu() {
  return (
    <React.Fragment>
      <li className="list-group-item">
        <Link className="link active" to="/">
          MOVIES
        </Link>
      </li>
      <li className="list-group-item">
        <Link className="link" to="/genres">
          Genres
        </Link>
      </li>
      <li className="list-group-item">
        <Link className="link" to="/languages">
          Languages
        </Link>
      </li>
      {user() ? (
        <li className="list-group-item">
          <Link className="link" to="/playlist">
            Playlist
          </Link>
        </li>
      ) : (
        <li className="list-group-item">
          <Link className="link" to="/login">
            Login
          </Link>
        </li>
      )}
    </React.Fragment>
  );
}

export default MobileMenu;
