import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectVertualMovies } from "../../redux/movies/movies.selector";

import category from "../../utils/movie.category";
import MovieItem from "./MovieItem";
import sortPerCountry from "../../func/sortPerCountry";

function MovieLanguageItem({ language, movies }) {
  const languageMovies = sortPerCountry(
    movies.filter((movie) => movie.language === language.language)
  ); // &&movie.countryName === language.countryName;

  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling

  const scrl = useRef(null);

  let scrollWidth = scrl?.current?.scrollWidth;
  let offsetWidth = scrl?.current?.offsetWidth;

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion
    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (scrl.current && scrollWidth === offsetWidth) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  }, [scrollWidth, offsetWidth]);

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <>
      {languageMovies.length > 0 ? (
        <section className="movies-tiles">
          <div className="container">
            <div className="head">
              <Link to="#" className="title">
                {language.language}
              </Link>
              <Link
                to={`/allmovies/${category.LANGUAGE}/${language.language}`}
                className="link"
              >
                See all »
              </Link>
            </div>

            <div
              className="
  swiffy-slider
  slider-item-show6
  slider-nav-visible
  slider-nav-touch
  slider-nav-page
  slider-nav-noloop
  slider-nav-autopause
  slider-indicators-sm
  slider-nav-animation
"
            >
              <ul
                className="slider-container"
                ref={scrl}
                onScroll={scrollCheck}
              >
                {languageMovies.map((n, index) => (
                  <MovieItem movieDetails={n} key={index} />
                ))}
              </ul>
              {scrollX !== 0 && (
                <button
                  type="button"
                  onClick={() => slide(-160)}
                  className="slider-nav slider-nav-prev"
                ></button>
              )}
              {!scrolEnd && (
                <button
                  type="button"
                  onClick={() => slide(+160)}
                  className="slider-nav slider-nav-next"
                ></button>
              )}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
});

export default connect(mapStateToProps)(MovieLanguageItem);
