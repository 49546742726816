import http from "./httpService";

const paymentEndpoint = "/payment";

export function makeCharge(obj) {
  return http.post(paymentEndpoint, obj);
}

export function makeTokenCharge(obj) {
  return http.post(paymentEndpoint + "/recurring", obj);
}

export function saveCardToken(obj) {
  return http.post(paymentEndpoint + "/token", obj);
}

export function getCardToken() {
  return http.get(paymentEndpoint + "/token");
}

export function getTransaction(transaction_id) {
  return http.get(paymentEndpoint + `/transactions/${transaction_id}`);
}

export function updateNewPay(bdy) {
  return http.put(paymentEndpoint + `/newpay`, bdy);
}
