import React from "react";
import { Link } from "react-router-dom";

function ExpiredLink(props) {
  return (
    <React.Fragment>
      <div class="col-lg-6">
        <h3 class="font-bold mb-5">The Link is expired</h3>
        <br />
        <br />
        <h5>
          The link sent to the mail is already expired please go to Forgotten
          Password link to resend another.
        </h5>
        <br />
        <br />
        <h3 class="font-bold mb-5">
          Or Go To<Link to="/"> Home Page</Link>
        </h3>
      </div>
    </React.Fragment>
  );
}

export default ExpiredLink;
