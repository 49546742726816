import React from "react";
import { FacebookShareButton } from "react-share";
import { FaFacebook } from "react-icons/fa";

function FacebookShare({ title, url }) {
  return (
    <FacebookShareButton
      url={url}
      title={title}
      style={{ marginRight: "14px" }}
    >
      <FaFacebook size={30} color="#c1c1c1" title="Share on Facebook" />
    </FacebookShareButton>
  );
}

export default FacebookShare;
