import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { loginUserAsync } from "../redux/users/users.action";
import { selectLoadingStatus as loading } from "./../redux/users/users.selector";

import Form from "../components/authentication/Form";
import Loader from "../elements/Loader";
//import GoogleSignIn from "../components/social.login/googleSignIn";
//import FacebookSignIn from "../components/social.login/facebookSignIn";

class AuthForm extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },
    error: {},
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().min(8).max(30).required().label("Password"),
  };

  doSubmit = async () => {
    const { loginUserAsync } = this.props;
    loginUserAsync(this.state.data);
    this.setState({
      data: {
        email: "",
        password: "",
      },
    });
  };

  componentDidMount() {
    document.title = "AfricanMovies | Login";
  }

  render() {
    const { loading } = this.props;
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div class="col-lg-6">
            <h3 class="font-bold mb-5">Sign In</h3>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("text", "email", "Enter your mail", "Email")}
              {this.renderInput(
                "password",
                "password",
                "Enter your password",
                "Password",
                "icon"
              )}
              {this.renderButton("Sign In")}
            </form>

            <div class="mt-4 d-flex justify-content-between flex-wrap">
              <span class="text-center">
                Or Create a{" "}
                <Link to="/registration" class="text-secondary">
                  new account.
                </Link>
              </span>
              <span class="">
                <Link to="/forgotpassword">Forgotten password?</Link>
              </span>
            </div>

            {/* <div class="row mt-4">
              <div class="col d-flex pe-0">
                <div class="faint-line my-auto"></div>
              </div>
              <div class="col text-muted text-center d-flex justify-content-center">
                Register Instead.
              </div>
              <div class="col d-flex ps-0">
                <div class="faint-line my-auto"></div>
              </div>
            </div> */}
            <div class="d-flex justify-content-center mt-4">
              {/* <GoogleSignIn />
              <FacebookSignIn /> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  loginUserAsync: (user) => dispatch(loginUserAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
