import React from "react";
import GenreItem from "../components/genres/GenreItem";
import SectionTitle from "../components/shared/SectionTitle";
import Loader from "../elements/Loader";

import { connect } from "react-redux";
import {
  selectLoadingStatus as loading,
  selectAllGenres as genres,
} from "../redux/moviesGenre/genres.selector";
import { createStructuredSelector } from "reselect";

function GenreSection({ genres, loading }) {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SectionTitle title={"Genres"} />
          <section class="container">
            <div class="content">
              <div class="movie-genre-page-view view">
                <div class="grid-playlist-wrap resp-content gbox-grid-playlist">
                  <div class="grid-playlist">
                    {genres.map((genre) => (
                      <GenreItem genre={genre} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  genres,
  loading,
});

export default connect(mapStateToProps)(GenreSection);
