import { useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../services/authServices";
import { verifyTokenAsync } from "../../redux/users/users.action";

function VerifyToken({ verifyTokenAsync }) {
  useEffect(() => {
    if (getCurrentUser()) verifyTokenAsync();
  }, [verifyTokenAsync]);
  return null;
}

const mapDispatchToProps = (dispatch) => ({
  verifyTokenAsync: () => dispatch(verifyTokenAsync()),
});

export default connect(null, mapDispatchToProps)(VerifyToken);
