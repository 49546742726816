import React, { useEffect } from "react";

import authBackground from "../assets/images/auth-background.jpg";
import AuthForm from "../elements/AuthForm";
import AuthHeader from "../elements/AuthHeader";
import AuthTerms from "../elements/AuthTerms";
import AuthTitleSide from "../elements/AuthTitleSide";

const authClass = "auth-page";
const positionClass = "position-relative";

function Login() {
  useEffect(() => {
    document.body.classList.add(authClass);
    document.body.classList.add(positionClass);
    document.body.style.backgroundImage = `url(${authBackground})`;
    return () => {
      document.body.classList.remove(authClass);
      document.body.classList.remove(positionClass);
      document.body.style.backgroundImage = null;
    };
  });
  return (
    <>
      <AuthHeader />
      <section class="auth-wrap position-absolute">
        <div class="row w-100 justify-content-center mt-5">
          <div class="col-md-8 col-lg-8 col-xl-7">
            <div class="auth-form-wrap p-3 p-md-5">
              <div class="row">
                <AuthTitleSide />
                <AuthForm />
              </div>
              <AuthTerms />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
