import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Genres from "./pages/Genres";
import Languages from "./pages/Languages";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Playlist from "./pages/Playlist";
import Logout from "./pages/Logout";
import AboutUs from "./pages/AboutUs";
import AllMovies from "./pages/AllMovies";
import ChangePassword from "./pages/ChangePassword";
import ConfirmMail from "./pages/ConfirmMail";
import Favorites from "./pages/Favorites";
import ForgotPassword from "./pages/ForgotPassword";
import HelpCenter from "./pages/HelpCenter";
import MightLike from "./pages/MightLike";
import ModifyPassword from "./pages/ModifyPassword";
import ProcessPayment from "./pages/ProcessPayment";
import ProcessTokenPayment from "./pages/ProcessTokenPayment";
import Profile from "./pages/Profile";
import SearchResult from "./pages/SearchResult";
import TrailerPlayer from "./pages/TrailerPlayer";
import Watchlist from "./pages/WatchList";
import MoviePlayer from "./pages/MoviePlayer";
import PaymentDetails from "./pages/PaymentDetails";
import MovieDetails from "./pages/MovieDetails";

import "./stylesheets/main.css";
import "./stylesheets/swiffy-slider.css";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/:location" element={<Home />} />
        <Route path="/genres" element={<Genres />} />
        <Route path="/languages" element={<Languages />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/playlist" element={<Playlist />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/allmovies/:movietype/:typename" element={<AllMovies />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/confirmmail" element={<ConfirmMail />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/mightlike/:genre" element={<MightLike />} />
        <Route path="/modifypassword/:userId" element={<ModifyPassword />} />
        <Route path="/playlist" element={<Playlist />} />
        <Route path="/processpayment" element={<ProcessPayment />} />
        <Route path="/processtokenpayment" element={<ProcessTokenPayment />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/search-result" element={<SearchResult />} />
        <Route path="/trailer/:id" element={<TrailerPlayer />} />
        <Route path="/watchlist" element={<Watchlist />} />
        <Route path="/movie/:movieid/:orderid" element={<MoviePlayer />} />
        <Route path="/paymentdetails" element={<PaymentDetails />} />
        <Route path="/moviedetails/:id" element={<MovieDetails />} />
      </Routes>
    </>
  );
}

export default App;
