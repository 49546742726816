import React from "react";
// import { ReactComponent as ZuluLogo } from "../../assets/images/svg/logo.svg";

function MobileLogo() {
  return (
    <React.Fragment>
      <div className="offcanvas-header">
        {/* <ZuluLogo className="logo offcanvas-title" alt="zulucast logo" /> */}
        African Movies
        <button
          type="button"
          className="btn-close text-reset text-white ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fas fa-times fa-lg"></i>
        </button>
      </div>
    </React.Fragment>
  );
}

export default MobileLogo;
