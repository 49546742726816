import React from "react";
import phoneCard from "../assets/images/phone-cardold.png";
import phoneWatch from "../assets/images/phone-downloadold.png";
import devices from "../assets/images/desktop-phoneold.png";

function AuthTitleSode() {
  return (
    <div class="col-lg-6 d-none d-lg-block">
      <h3 class="font-size-24 font-bold">
        World’s largest Nollywood library. Watch anytime, anywhere.
      </h3>
      <div class="row mt-5">
        <div class="col-2">
          <img src={devices} width="48" alt="" />
        </div>
        <div class="col">
          <div class="font-bold">Watch on any device, no extra payment</div>
          <div class="text-muted">
            Stream on your phone, tablet and laptop without paying more.
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-2">
          <img src={phoneWatch} height="36" alt="" />
        </div>
        <div class="col">
          <div class="font-bold">Watch anytime, anywhere</div>
          <div class="text-muted">Enjoy seamless Video content streaming.</div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-2">
          <img src={phoneCard} height="36" alt="" />
        </div>
        <div class="col">
          <div class="font-bold">Simple and easy Payment</div>
          <div class="text-muted">No commitments, Pay anytime.</div>
        </div>
      </div>
    </div>
  );
}

export default AuthTitleSode;
