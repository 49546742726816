import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { connect } from "react-redux";
import {
  addUserOrderAsync,
  removeUserOrderAsync,
} from "../redux/movies/movies.action";
import {
  getCardToken,
  saveCardToken,
  getTransaction,
  updateNewPay,
} from "../services/paymentServices";

function ProcessPayment({ addUserOrderAsync, removeUserOrderAsync }) {
  let navigate = useNavigate();
  const [dialogState, setDialogState] = useState({
    open: false,
    newOpen: false,
  });
  const [tid, setTid] = useState();
  const location = useLocation();

  const handleDialogOpen = () => setDialogState({ ...dialogState, open: true });

  const handleDialogClose = async () => {
    setDialogState({ ...dialogState, open: false });
    window.location = "/playlist";
  };

  const handleNewDialogOpen = () =>
    setDialogState({ ...dialogState, newOpen: true });

  const handleNewDialogClose = async () => {
    await updateNewPay({ isNewCard: false });
    setDialogState({ ...dialogState, newOpen: false });
    window.location = "/playlist";
  };

  const saveCardDetails = async (isNewCard) => {
    toast("Saving card details | Flutterwave", {
      position: toast.POSITION.TOP_CENTER,
    });
    try {
      const { data } = await getTransaction(tid);
      await saveCardToken({
        isNewCard,
        transactionID: tid,
        token: data.card.token,
        first6Digits: data.card.first_6digits,
        last4Digits: data.card.last_4digits,
        country: data.card.country,
        expiry: data.card.expiry,
        cardType: data.card.type,
      });
      setDialogState({ open: false, newOpen: false });
      window.location = "/playlist";
    } catch (error) {
      toast.error("Failed to save card details.");
      Sentry.captureException("Error saving card details:", error);
    }
  };

  useEffect(() => {
    const handlePaymentStatus = async () => {
      const { status, transaction_id } = queryString.parse(location.search);

      if (status === "cancelled") {
        await removeUserOrderAsync();
        await updateNewPay({ isNewCard: false });
        window.location = "/";
      } else {
        await addUserOrderAsync();
        setTid(transaction_id);

        try {
          const { data } = await getCardToken();
          const token = data?.tokenPayload;
          const { data: transaction } = await getTransaction(transaction_id);
          if (!token && transaction.payment_type === "card") {
            handleDialogOpen();
          } else if (token.isNewPay && transaction.payment_type === "card") {
            handleNewDialogOpen();
          } else {
            window.location = "/playlist";
          }
        } catch (error) {
          toast.error("Failed to process payment.");
          Sentry.captureException("Error processing payment:", error);
        }
      }
    };

    handlePaymentStatus();
  }, [location.search, navigate, addUserOrderAsync, removeUserOrderAsync]);

  const renderDialogContentText = (isNewCard = false) => (
    <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>
      {isNewCard
        ? `You have used a new card instead of the saved one, do you want to save this one for the subsequent charges? Note that saving this one will update the currently saved one.`
        : `Would you like to save your card details for subsequent payment?`}
      <br />
      <span style={{ fontSize: "13px", color: "black" }}>
        Our secure card payments are managed by Flutterwave, a global payment
        provider trusted by the biggest brands on the Internet. For your
        security, ZuluCast does not access or store your payment details.
        (SSL-ENCRYPTED)
      </span>
    </DialogContentText>
  );

  return queryString.parse(location.search).status === "cancelled" ? (
    <>
      <h1 style={{ margin: "50px" }}>Payment Failed, Redirecting...</h1>
    </>
  ) : (
    <>
      <h1 style={{ margin: "50px" }}>Payment Successful, Redirecting...</h1>
      <Dialog
        open={dialogState.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Secure Payment."} <FaCcVisa size={20} color="#21baef" />{" "}
          <FaCcMastercard size={20} color="#21baef" />
        </DialogTitle>
        <DialogContent>{renderDialogContentText()}</DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose} className={`btn btn-secondary`}>
            No, don't
          </button>
          <button
            onClick={() => saveCardDetails(false)}
            className={`btn btn-primary`}
            autoFocus
          >
            Yes, save.
          </button>
        </DialogActions>
      </Dialog>

      {/* second dialog for new card userd */}
      <Dialog
        open={dialogState.newOpen}
        onClose={handleNewDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Secure Payment."} <FaCcVisa size={20} color="#21baef" />{" "}
          <FaCcMastercard size={20} color="#21baef" />
        </DialogTitle>
        <DialogContent>{renderDialogContentText(true)}</DialogContent>
        <DialogActions>
          <button
            onClick={handleNewDialogClose}
            className={`btn btn-secondary`}
          >
            No, don't
          </button>
          <button
            onClick={() => saveCardDetails(true)}
            className={`btn btn-primary`}
            autoFocus
          >
            Yes, save.
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addUserOrderAsync: () => dispatch(addUserOrderAsync()),
  removeUserOrderAsync: () => dispatch(removeUserOrderAsync()),
});

export default connect(null, mapDispatchToProps)(ProcessPayment);
