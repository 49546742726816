import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VscClose } from "react-icons/vsc";

import { connect } from "react-redux";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";
import { fetchMoviesAsync } from "../redux/movies/movies.action";

import Loader from "../elements/Loader";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function TrailerPlayer({ allMovies, fetchMoviesAsync }) {
  let navigate = useNavigate();
  const handleClose = () => navigate(-1);

  useEffect(() => {
    fetchMoviesAsync();
  }, [fetchMoviesAsync]);

  const { id } = useParams();
  const trailerMovie = allMovies.find((m) => m._id === id);

  const [width, height] = useWindowSize();

  return (
    <>
      {!trailerMovie ? (
        <Loader />
      ) : (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", zIndex: 1000 }}>
            <VscClose
              style={{ cursor: "pointer" }}
              size={30}
              onClick={handleClose}
            />
          </div>
          <video
            autoPlay
            playsInline
            loop
            controls
            height={height}
            width={width}
          >
            <source src={trailerMovie.movieTrailerURL} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  allMovies: selectVertualMovies,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrailerPlayer);
