import React, { useEffect } from "react";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";
import Loader from "../elements/Loader";
import AllFavSection from "../elements/AllFavSection";

import { connect } from "react-redux";
import {
  fetchMoviesAsync,
  fetchFavMoviesAsync,
} from "../redux/movies/movies.action";
import {
  selectVertualMovies,
  selectFavoriteMovies,
} from "../redux/movies/movies.selector";
import { selectLoadingStatus as movieLoading } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";

function Favorites({
  fetchMoviesAsync,
  fetchFavMoviesAsync,
  movieLoading,
  favmovies,
  movies,
}) {
  useEffect(() => {
    //load  pages
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount && reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }

    fetchMoviesAsync();
    fetchFavMoviesAsync();
  }, [fetchMoviesAsync, fetchFavMoviesAsync]);

  return (
    <React.Fragment>
      {movieLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Navbar />
          <h3 className="watchlist-res">My Favorited Movies</h3>
          <AllFavSection
            listtype={"favorite"}
            movies={movies}
            favorites={favmovies}
          />
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchFavMoviesAsync: () => dispatch(fetchFavMoviesAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  favmovies: selectFavoriteMovies,
  movieLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
