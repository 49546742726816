import React from "react";
import Joi from "joi-browser";
import { RxCross2 } from "react-icons/rx";
import { PiCheckBold } from "react-icons/pi";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectLoadingStatus as loading } from "../redux/users/users.selector";
import { passwordModifyAsync } from "../redux/users/users.action";

import { Link } from "react-router-dom";
import Loader from "../elements/Loader";
import Form from "../components/authentication/Form";

const PasswordCheck = (verified) =>
  verified ? <PiCheckBold color="green" /> : <RxCross2 color="red" />;

class ModifyPassAuth extends Form {
  state = {
    data: {
      userId: "",
      password: "",
      password_confirm: "",
    },
    passwordComplexity: {},
    error: {},
  };

  schema = {
    userId: Joi.string().label("userId"),
    password: Joi.string().required().label("New Password"),
    password_confirm: Joi.ref("password"),
  };

  doSubmit = async () => {
    const { passwordModifyAsync } = this.props;
    passwordModifyAsync(this.state.data);
  };

  componentDidMount() {
    document.title = "African Movies | Reset Password";
    this.setState({
      data: {
        userId: this.props.userId,
        password: "",
        password_confirm: "",
      },
    });
  }

  render() {
    const { loading } = this.props;
    const { passwordComplexity } = this.state;

    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div class="col-lg-6">
            <h3 class="font-bold mb-5">Reset Password</h3>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "password",
                "password",
                "Create your password",
                "New Password",
                "icon"
              )}
              {this.renderInput(
                "password",
                "password_confirm",
                "Confirm password",
                "Confirm password",
                "icon"
              )}
              <div style={{ marginTop: "20px" }}>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyLength)} The
                  password must be at least 8 characters long.
                </div>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyDigit)} It
                  must contain at least one digit (0-9).
                </div>
                <div class="text-muted" style={{ textAlignLast: "center" }}>
                  {PasswordCheck(passwordComplexity?.passwordVerifyCase)} It
                  must include a combination of uppercase and lowercase letters
                  (A-Z and a-z).
                </div>
              </div>
              {this.renderButton("Reset My Password")}
            </form>

            <div class="mt-4 d-flex justify-content-between flex-wrap">
              <span class="text-center">
                Or{" "}
                <Link to="/login" class="text-secondary">
                  Login.
                </Link>
              </span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  passwordModifyAsync: (user) => dispatch(passwordModifyAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPassAuth);
