import React from "react";
import { TwitterShareButton } from "react-share";
import { AiFillTwitterCircle } from "react-icons/ai";

export default function TwitterShare({ title, url }) {
  return (
    <TwitterShareButton url={url} title={title} style={{ marginRight: "14px" }}>
      <AiFillTwitterCircle size={30} color="#c1c1c1" title="Share on Twitter" />
    </TwitterShareButton>
  );
}
