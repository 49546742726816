import React from "react";
import { Link } from "react-router-dom";
import TawkTo from "tawkto-react";

import { getCurrentUser as user } from "../../services/authServices";

const defaultProfile =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

function MobileNavAuth() {
  const currentUser = user();

  const profileURL = localStorage.getItem("profileURL");
  const username = localStorage.getItem("username");

  const startChat = () => {
    var tawk = new TawkTo("62377aba1ffac05b1d7f851f", "1fukbeqts");
    tawk.onStatusChange((status) => {
      alert(
        "A chat box will show up on the bottom right corner, kindly click to chat with our support team."
      );
    });
  };

  const profileImage = profileURL || currentUser?.profileURL || defaultProfile;

  return (
    <React.Fragment>
      {/* <!-- when logged out --> */}
      {!currentUser && (
        <li className="list-group-item">
          <div className="d-grid">
            <Link className="btn btn-primary-gradient" to="/login">
              let's start
            </Link>
          </div>
        </li>
      )}

      {/* <!-- when logged in --> */}
      {currentUser && (
        <div className="profile">
          <li className="list-group-item profile">
            <div className="text-center">
              <span className="text-muted d-flex align-items-center">
                <div className="profile-img-wrap me-2">
                  <img src={profileImage} alt="User profile" />
                </div>
                {username || currentUser.username}
              </span>
            </div>
          </li>
          <hr />
          <li className="list-group-item">
            <Link className="link" to="/profile">
              Manage Profile
            </Link>
          </li>
          <li className="list-group-item">
            <Link className="link" to="/watchlist">
              My Watchlist
            </Link>
          </li>
          <li className="list-group-item">
            <Link className="link" to="/favorites">
              My Favorites Movies
            </Link>
          </li>
          <li className="list-group-item">
            <span
              className="link"
              style={{ cursor: "pointer" }}
              onClick={startChat}
            >
              Contact Us
            </span>
          </li>
          <li className="list-group-item">
            <Link className="link" to="/helpcenter">
              Help Center
            </Link>
          </li>
          <li className="list-group-item">
            <Link className="link" to="/logout">
              Logout
            </Link>
          </li>
        </div>
      )}
    </React.Fragment>
  );
}

export default MobileNavAuth;
