import React, { useEffect } from "react";
import Loader from "../elements/Loader";
import constants from "../utils/constants";
import * as Sentry from "@sentry/react";

const REGISTRY_KEY = process.env.REACT_APP_API_REGISTRY_KEY;
const CURRENCY_KEY = process.env.REACT_APP_CURRENCY_KEY;
const URL = `https://api.ipregistry.co/?key=${REGISTRY_KEY}`;

export default function Landing() {
  async function getLocation() {
    const isocode = constants.ISOCODE;
    if (!REGISTRY_KEY || !CURRENCY_KEY) {
      Sentry.captureException("Keys | Landing.jsx, Could not read API key");
      window.location = `/all`;
      return;
    }
    try {
      const storedCode = localStorage.getItem(isocode);
      if (storedCode) {
        window.location = `/${storedCode}`;
        return;
      }
      const response = await fetch(URL);
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();

      const dataiso = data?.location?.country?.code.toLowerCase();

      if (dataiso) {
        localStorage.setItem(isocode, dataiso);
        window.location = `/${dataiso}`;
      } else window.location = `/all`;
    } catch (error) {
      localStorage.removeItem(isocode);
      window.location = `/all`;
    }
  }

  useEffect(() => {
    getLocation();
  }, []);
  return <Loader />;
}
