import React from "react";
import AllMovieItem from "../components/allMovies/AllMovieItem";
import sortPerCountry from "../func/sortPerCountry";
import category from "../utils/movie.category";

import "../stylesheets/allmovies.css";

function AllMoviesSection({ movietype, typename, movies }) {
  let filteredMovies;
  if (movietype === category.GENRE)
    filteredMovies = sortPerCountry(movies.filter((m) => m.genre === typename));
  else
    filteredMovies = sortPerCountry(
      movies.filter((m) => m.language === typename)
    );

  return (
    <React.Fragment>
      <section class="movies-tiles">
        <div class="container">
          <div
            class="
      swiffy-slider
      slider-item-show6
      slider-nav-visible
      slider-nav-touch
      slider-nav-page
      slider-nav-noloop
      slider-nav-autopause
      slider-indicators-sm
      slider-nav-animation
    "
          >
            <ul class="AllCont">
              {filteredMovies.length > 0 ? (
                filteredMovies.map((m, index) => (
                  <AllMovieItem movie={m} key={index} />
                ))
              ) : (
                <h2>No movies was found.</h2>
              )}
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AllMoviesSection;
