import React from "react";

function SlideButton(props) {
  const { slideId } = props;
  return (
    <button
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide-to={slideId}
      className={`${slideId === 0 ? "active" : ""}`}
      //aria-current={`${slideId === 0 ? "true" : "false"}`}
      aria-label={`Slide ${slideId + 1}`}
    ></button>
  );
}

export default SlideButton;
