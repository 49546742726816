import React from "react";
import { Link } from "react-router-dom";
import WatchMovie from "../movieLink/WatchMovie";

function AllMovieItem({ movie }) {
  return (
    <React.Fragment>
      <li className="movie-item" style={{ marginBottom: "28px" }}>
        <Link to={`/moviedetails/${movie?._id}`}>
          <img src={movie?.moviePictureURL} alt="" />
        </Link>
        <div className="caption">
          <span className="movie-title">{movie?.title}</span> |
          <span className="movie-year">{movie?.releaseYear}</span>
        </div>
        <div className="overlay">
          <div className="top">
            <Link to={`/moviedetails/${movie?._id}`}>
              <h4 className="overlay-title">{movie?.title}</h4>
              <div className="overlay-subtitle">
                {movie?.releaseYear} | {movie?.duration} min
              </div>
              <div className="content">
                <div className="overlay-description">{movie?.description}</div>
              </div>
            </Link>

            <Link to={`/moviedetails/${movie?._id}`} className="title-wrap">
              <span className="title-head">Director:</span>
              <span className="title-name">{movie?.actor}</span>
            </Link>
            <Link to={`/moviedetails/${movie?._id}`} className="title-wrap">
              <span className="title-head">Language:</span>
              <span className="title-name">{movie?.language}</span>
            </Link>
          </div>
          <div className="bottom">
            <div className="d-grid gap-2">
              <Link
                to={`/trailer/${movie?._id}`}
                className="btn btn-outline-primary"
              >
                watch trailer
              </Link>
              <WatchMovie movie={movie} cssstyle={"btn btn-primary"} />
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
}

export default AllMovieItem;
