import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../elements/Loader";
import { toast } from "react-toastify";
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import constants from "../../utils/constants";
import * as Sentry from "@sentry/react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectOrders } from "../../redux/movies/movies.selector";
import {
  addOrderAsync,
  addExpiryDateAsync,
} from "../../redux/movies/movies.action";
import {
  getCardToken,
  saveCardToken,
  updateNewPay,
} from "../../services/paymentServices";
import { getCurrentUser as user } from "../../services/authServices";
import { makeCharge, makeTokenCharge } from "../../services/paymentServices";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const REGISTRY_URL = `https://api.ipregistry.co/?key=${process.env.REACT_APP_API_REGISTRY_KEY}`;
const CURRENCY_URL = `https://api.fastforex.io/fetch-one?from=USD&api_key=${process.env.REACT_APP_CURRENCY_KEY}`;

function WatchMovie({
  movie,
  orders,
  addOrderAsync,
  addExpiryDateAsync,
  cssstyle,
}) {
  const [open, setOpen] = useState(false);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardExist, setCardExist] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState(null);
  const [card, setCard] = useState({ token: "", last4Digits: "" });

  const currentUser = user();

  let userOrder = orders.filter((o) => o.movieId === movie?._id);

  const USD = constants.USD;
  const ghana = constants.GHANA.toLowerCase();
  const nigeria = constants.NIGERIA.toLowerCase();

  const {
    title,
    price,
    description,
    actor,
    duration,
    _id: movieId,
    videoId,
    moviePictureURL,
    movieVideoURL,
  } = movie || {};

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);
  const toggleOpenPayDialog = useCallback(
    () => setOpenPayDialog((prev) => !prev),
    []
  );

  const fetchCountryData = async () => {
    const response = await fetch(REGISTRY_URL);
    return response.json();
  };

  const fetchCurrencyRate = async (countryCode) => {
    const response = await fetch(`${CURRENCY_URL}&to=${countryCode}`);
    return response.json();
  };

  const getAmountByCurrency = async (price) => {
    try {
      const countryData = await fetchCountryData();

      // Determine country name
      const countryName = countryData?.location?.country?.name?.toLowerCase();
      const currencyCode = countryData?.currency?.code;

      if (countryName === ghana || countryName === nigeria) {
        const currencyData = await fetchCurrencyRate(currencyCode);

        // Retrieve currency exchange rate for GHS
        const exchangeRate = currencyData.result[currencyCode];

        // Calculate amount in GHS
        const amountExchanged = exchangeRate * price;

        return { amount: amountExchanged, currency: currencyCode };
      } else {
        // Default to USD if country is not Ghana
        return { amount: price, currency: USD };
      }
    } catch (error) {
      // Handle any errors during API calls
      handleError(error);
      return { amount: price, currency: USD }; // Default to USD in case of error
    }
  };

  const handlePayment = async (useNewCard) => {
    setLoading(true);
    try {
      const amountAndCurrency = await getAmountByCurrency(price);
      const { email, username } = currentUser;
      const { data } = useNewCard
        ? await makeCharge({ email, username, ...amountAndCurrency })
        : await makeTokenCharge({
            email,
            token: card.token,
            ...amountAndCurrency,
          });

      if (!useNewCard) {
        if (data.status === constants.PAYMENT_SUCCESS) {
          const { id, card: cardData } = data?.data;
          await saveCardToken({
            transactionID: String(id),
            token: cardData.token,
            first6Digits: cardData.first_6digits,
            last4Digits: cardData.last_4digits,
          });
          window.location = "/processtokenpayment";
        } else {
          toast.error(data.message);
        }
      } else {
        await updateNewPay({ isNewCard: true });
        window.location = data.link;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    toast.error(error.message);
    Sentry.captureException(error);
  };

  const initializeCardData = useCallback(async () => {
    if (currentUser) {
      const { data } = await getCardToken();
      if (data?.tokenPayload) {
        setCardExist(true);
        setCard(data?.tokenPayload);
      } else {
        setCardExist(false);
      }
    }
  }, []);

  useEffect(() => {
    initializeCardData();
  }, [initializeCardData]);

  const startWatching = async () => {
    if (!currentUser) {
      localStorage.setItem("from", movie?._id);
      window.location = "/login";
      return;
    }
    userOrder = userOrder.find((o) => o.email === currentUser.email);
    if (userOrder) {
      setLoading(true);
      if (!userOrder.startWatch) {
        await addExpiryDateAsync({ _id: userOrder._id });
      }
      setLoading(false);
      window.location = `/movie/${userOrder.movieId}/${userOrder._id}`;
      return;
    } else {
      setLoading(true);
      const amountAndCurrency = await getAmountByCurrency(price);
      setCurrency(amountAndCurrency?.currency);
      setAmount(amountAndCurrency?.amount);
      setLoading(false);
      toggleOpenPayDialog();
    }
  };

  const makePayment = async () => {
    setLoading(true);
    try {
      const { email, username } = currentUser;
      addOrderAsync({
        title,
        price,
        description,
        actor,
        duration,
        movieId,
        videoId,
        moviePictureURL,
        movieVideoURL,
      });
      if (cardExist) {
        setLoading(false);
        toggleOpen();
        toggleOpenPayDialog();
      } else {
        const amountAndCurrency = await getAmountByCurrency(price);
        const { data } = await makeCharge({
          email,
          username,
          ...amountAndCurrency,
        });
        setLoading(false);
        window.location = data?.link;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <span className={cssstyle} onClick={startWatching}>
            Watch Movie
          </span>
          <RecurringPaymentDialog
            open={open}
            onClose={toggleOpen}
            card={card}
            onNewCardPayment={() => handlePayment(true)}
            onExistingCardPayment={() => handlePayment(false)}
          />
          <PaymentDialog
            open={openPayDialog}
            onClose={toggleOpenPayDialog}
            currency={currency}
            amount={amount}
            title={title}
            onPay={makePayment}
          />
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addOrderAsync: (order) => dispatch(addOrderAsync(order)),
  addExpiryDateAsync: (payload) => dispatch(addExpiryDateAsync(payload)),
});

const mapStateToProps = createStructuredSelector({
  orders: selectOrders,
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchMovie);

const PaymentDialog = ({ open, onClose, title, onPay, currency, amount }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Secure Payment"} <FaCcVisa size={20} color="#21baef" />{" "}
      <FaCcMastercard size={20} color="#21baef" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
        style={{ color: "black" }}
      >
        You will be redirected to make payment of {currency}{" "}
        {amount?.toFixed(2) ?? ""} to watch {title}. <br />
        <span style={{ fontSize: "13px", color: "black" }}>
          Our secure card payments are managed by Flutterwave, a global payment
          provider trusted by the biggest brands on the Internet. For your
          security, ZuluCast does not access or store your payment details.
          (SSL-ENCRYPTED)
        </span>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <button onClick={onClose} className="btn btn-secondary">
        Cancel
      </button>
      <button onClick={onPay} className="btn btn-primary" autoFocus>
        Pay and watch.
      </button>
    </DialogActions>
  </Dialog>
);

const RecurringPaymentDialog = ({
  open,
  onClose,
  card,
  onNewCardPayment,
  onExistingCardPayment,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Secure Payment"} <FaCcVisa size={20} color="#21baef" />{" "}
      <FaCcMastercard size={20} color="#21baef" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
        style={{ color: "black" }}
      >
        Would you like to use your card ending with{" "}
        {card ? `...${card.last4Digits}` : " ...."}? <br />
        <span style={{ fontSize: "13px", color: "black" }}>
          Our secure card payments are managed by Flutterwave, a global payment
          provider trusted by the biggest brands on the Internet. For your
          security, ZuluCast does not access or store your payment details.
          (SSL-ENCRYPTED)
        </span>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <button onClick={onClose} className="btn btn-secondary">
        Cancel
      </button>
      <button onClick={onNewCardPayment} className="btn btn-secondary">
        No, don't.
      </button>
      <button
        onClick={onExistingCardPayment}
        className="btn btn-primary"
        autoFocus
      >
        Yes, use and charge.
      </button>
    </DialogActions>
  </Dialog>
);
