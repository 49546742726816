import React, { Component } from "react";
import Joi from "joi-browser";

import Input from "./Input";

class Form extends Component {
  state = {
    data: {},
    error: {},
    passwordComplexity: {
      passwordVerifyLength: false,
      passwordVerifyCase: false,
      passwordVerifyDigit: false,
    },
  };

  hasLowerAndUpper(str) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])/;
    return regex.test(str);
  }

  hasDigit(text) {
    return /\d/.test(text);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error: error || {} });
    if (error) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const error = { ...this.state.error };
    const errorMessage = this.validateInput(input);
    if (errorMessage) error[input.name] = errorMessage;
    else delete error[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ error, data });

    if (input.name === "password") {
      const isValidLength = input.value.length >= 8;
      const isValidCase = this.hasLowerAndUpper(input.value);
      const isValidDigit = this.hasDigit(input.value);

      this.setState((prevState) => ({
        passwordComplexity: {
          ...prevState.passwordComplexity,
          passwordVerifyLength: isValidLength,
          passwordVerifyCase: isValidCase,
          passwordVerifyDigit: isValidDigit,
        },
      }));
    }
  };

  validateInput = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    if (!error) return null;
    return error.details[0].message;
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  renderInput(type, name, placeholder, label, icon) {
    const { data, error } = this.state;
    return (
      <Input
        name={name}
        type={type}
        label={label}
        icon={icon}
        value={data[name]}
        error={error[name]}
        placeholder={placeholder}
        onChange={this.handleChange}
      />
    );
  }

  renderButton(label) {
    return (
      <div class="d-grid  mt-4">
        <button
          type="submit"
          className={`btn btn-primary ${this.validate() && "disabled"}`}
        >
          {label}
        </button>
      </div>
    );
  }

  render() {
    return;
  }
}

export default Form;
