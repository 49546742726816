import React from "react";
import MovieGenreList from "../components/movieList/MovieGenreList";

function MovieList() {
  return (
    <React.Fragment>
      <MovieGenreList />
    </React.Fragment>
  );
}

export default MovieList;
