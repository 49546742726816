import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import { RxCross2 } from "react-icons/rx";
import { PiCheckBold } from "react-icons/pi";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { passwordResetAsync } from "../redux/users/users.action";
import { selectLoadingStatus as loading } from "./../redux/users/users.selector";

import Form from "../components/authentication/Form";
import Loader from "../elements/Loader";

const PasswordCheck = (verified) =>
  verified ? <PiCheckBold color="green" /> : <RxCross2 color="red" />;

class ChangePasswordForm extends Form {
  state = {
    data: {
      oldPassword: "",
      password: "",
      password_confirm: "",
    },
    passwordComplexity: {},
    error: {},
  };

  schema = {
    oldPassword: Joi.string().min(8).max(30).required().label("Old Password"),
    password: Joi.string().required().label("New Password"),
    password_confirm: Joi.ref("password"),
  };

  doSubmit = async () => {
    const { passwordResetAsync } = this.props;
    this.state.passwordComplexity = {};
    passwordResetAsync(this.state.data);
  };

  componentDidMount() {
    document.title = "African Movies | Reset Password";
  }

  render() {
    const { loading } = this.props;
    const { passwordComplexity } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div class="col-lg-6">
            <h3 class="font-bold mb-5">Change Your Password</h3>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "password",
                "oldPassword",
                "Enter old password",
                "Old Password",
                "icon"
              )}
              {this.renderInput(
                "password",
                "password",
                "Create New Password",
                "New Password",
                "icon"
              )}
              {this.renderInput(
                "password",
                "password_confirm",
                "Confirm Password",
                "Confirm Password",
                "icon"
              )}
              <div style={{ marginTop: "20px" }}>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyLength)} The
                  password must be at least 8 characters long.
                </div>
                <div class="text-muted">
                  {PasswordCheck(passwordComplexity?.passwordVerifyDigit)} It
                  must contain at least one digit (0-9).
                </div>
                <div class="text-muted" style={{ textAlignLast: "center" }}>
                  {PasswordCheck(passwordComplexity?.passwordVerifyCase)} It
                  must include a combination of uppercase and lowercase letters
                  (A-Z and a-z).
                </div>
              </div>
              {this.renderButton("Change Password")}
            </form>

            <div class="mt-4 d-flex justify-content-between flex-wrap">
              <span class="text-center">
                Or{" "}
                <Link to="/login" class="text-secondary">
                  Login Instead.
                </Link>
              </span>
            </div>
            <div class="d-flex justify-content-center mt-4"></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  passwordResetAsync: (user) => dispatch(passwordResetAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
