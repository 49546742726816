import React from "react";
import { Link } from "react-router-dom";

function AuthTerms() {
  return (
    <div class="text-center mt-5">
      <small class="text-muted ">
        By signing in or signing up, you aggree to the Terms & Conditions and{" "}
        <Link to="">Privacy Policy.</Link> And{" "}
        <Link to="/">Terms of Service</Link> apply.
      </small>
    </div>
  );
}

export default AuthTerms;
