import React from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLongevity } from "../redux/movies/movies.selector";

import Slides from "../components/banner/Slides";
import Video from "../components/banner/Video";

function Banner({ longevity }) {
  return (
    <React.Fragment>
      <section className="banner">
        {longevity["slidesAsBanner"] ? <Slides /> : <Video />}
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  longevity: selectLongevity,
});

export default connect(mapStateToProps)(Banner);
