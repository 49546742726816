import React from "react";
import { Link } from "react-router-dom";
import category from "../../utils/movie.category";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectVertualMovies as movies } from "../../redux/movies/movies.selector";

function GenreItem({ genre, movies }) {
  const { genrePictureURL, name } = genre || {};
  const movie = movies.filter((m) => m.genre === name);
  return (
    <>
      {movie.length > 0 ? (
        <div class="g-box eros-box movie-g-box" title={name}>
          <Link
            to={`/allmovies/${category.GENRE}/${name}`}
            class="g-box-content-wrap"
          >
            <div class="g-box-content">
              <div class="thumb-wrap">
                <div class="l-img-wrap g-box-img animated-gradient">
                  <div class="img-placeholder">
                    {genrePictureURL ? (
                      <img
                        className="genreimage"
                        src={genrePictureURL}
                        alt={`${name}`}
                      />
                    ) : (
                      <h3>{name}</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  movies,
});

export default connect(mapStateToProps)(GenreItem);
