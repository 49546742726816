import React from "react";
import AllMovieItem from "../components/allMovies/AllMovieItem";

import "../stylesheets/allmovies.css";

function AllMightLikeSection({ movies }) {
  return (
    <React.Fragment>
      <section class="movies-tiles">
        <div class="container">
          <div
            class="
  swiffy-slider
  slider-item-show6
  slider-nav-visible
  slider-nav-touch
  slider-nav-page
  slider-nav-noloop
  slider-nav-autopause
  slider-indicators-sm
  slider-nav-animation
"
          >
            <ul class="AllCont">
              {movies.map((m, index) => (
                <AllMovieItem movie={m} key={index} />
              ))}
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AllMightLikeSection;
