import React from "react";
import { Link } from "react-router-dom";

import WatchMovie from "../movieLink/WatchMovie";

function MovieItem({ movieDetails }) {
  const {
    _id,
    moviePictureURL,
    title,
    releaseYear,
    duration,
    description,
    actor,
    language,
  } = movieDetails;
  return (
    <li className="movie-item">
      <Link to={`/moviedetails/${_id}`}>
        <img src={moviePictureURL} alt="" />
      </Link>
      <div className="caption">
        <span className="movie-title">{title || "Untitled"}</span> |
        <span className="movie-year">{releaseYear || "N/A"}</span>
      </div>
      <div className="overlay">
        <div className="top">
          <Link to={`/moviedetails/${_id}`}>
            <h4 className="overlay-title">{title || "Untitled"}</h4>
            <div className="overlay-subtitle">
              {releaseYear || "N/A"} | {duration || "N/A"} min
            </div>
            {description && (
              <div className="content">
                <div className="overlay-description">{description}</div>
              </div>
            )}
          </Link>
          {actor && (
            <Link to={`/moviedetails/${_id}`} className="title-wrap">
              <span className="title-head">Author(s):</span>
              <span className="title-name">{actor}</span>
            </Link>
          )}
          {language && (
            <Link to={`/moviedetails/${_id}`} className="title-wrap">
              <span className="title-head">Language:</span>
              <span className="title-name">{language}</span>
            </Link>
          )}
        </div>
        <div className="bottom">
          <div className="d-grid gap-2">
            <Link to={`/trailer/${_id}`} className="btn btn-outline-primary">
              watch trailer
            </Link>
            <WatchMovie movie={movieDetails} cssstyle={"btn btn-primary"} />
          </div>
        </div>
      </div>
    </li>
  );
}

export default MovieItem;
