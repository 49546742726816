import React, { useState } from "react";
import { getCurrentUser as user } from "../../services/authServices";
import { BsBookmarkCheck, BsBookmarkPlus } from "react-icons/bs";
import Loader from "../../elements/Loader";
import * as Sentry from "@sentry/react";

import { connect } from "react-redux";
import { selectWatchlistMovies } from "../../redux/movies/movies.selector";
import { addWatchlistMovieAsync } from "../../redux/movies/movies.action";
import { createStructuredSelector } from "reselect";
import { selectLoadingStatus as loading } from "../../redux/movies/movies.selector";

const hoverColor = "#21baef";
const white = "white";

function Watchlist({ watchlist, movie, addWatchlistMovieAsync, loading }) {
  const currentUser = user();
  const [color, setColor] = useState(white);
  let list = watchlist.filter((f) => f.movieId === movie._id);

  const { _id, title } = movie || {};

  const handleClick = () => {
    try {
      if (!currentUser) {
        localStorage.setItem("from", _id);
        window.location = "/login";
        return;
      }
      addWatchlistMovieAsync({
        movieId: _id,
        movieTitle: title,
      });
      window.location.reload();
    } catch (error) {
      Sentry.captureException("Watchlist.jsx ", error);
    }
  };

  const isInWatchlist = () => {
    if (!currentUser) return false;
    if (list.length <= 0) return false;
    list = list.filter((f) => f.userId === currentUser._id);
    if (!list[0]) return false;
    return true;
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <span
          class="watchlist-icon-wrap me-3"
          title={isInWatchlist() ? "Remove from watchlist" : "Add to watchlist"}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          {isInWatchlist() ? (
            <BsBookmarkCheck size={30} color={hoverColor} />
          ) : (
            <BsBookmarkPlus
              size={30}
              color={color}
              onMouseEnter={() => setColor(hoverColor)}
              onMouseLeave={() => setColor(white)}
            />
          )}
        </span>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  watchlist: selectWatchlistMovies,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  addWatchlistMovieAsync: (payload) =>
    dispatch(addWatchlistMovieAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Watchlist);
