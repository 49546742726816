import React, { useEffect } from "react";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";
import SectionTitle from "../components/shared/SectionTitle";
import MovieLanguageList from "../components/movieList/MovieLanguageList";

import { connect } from "react-redux";
import { fetchLanguageAsync } from "../redux/country/country.action";
import {
  fetchMoviesAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";
import { getCurrentUser } from "../services/authServices";

function Languages({ fetchLanguageAsync, fetchMoviesAsync, fetchOrderAsync }) {
  useEffect(() => {
    fetchLanguageAsync();
    fetchMoviesAsync();
    if (getCurrentUser()) fetchOrderAsync();
    //load page
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount && reloadCount < 1) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, [fetchLanguageAsync, fetchMoviesAsync, fetchOrderAsync]);

  return (
    <>
      <Navbar />
      <SectionTitle title={"Languages"} />
      <MovieLanguageList />
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
});

export default connect(null, mapDispatchToProps)(Languages);
