export default function sortPerCountry(movies) {
  if (!Array.isArray(movies) || movies.length === 0) return [];

  const location = localStorage.getItem("isocode")?.toLowerCase();
  if (location) {
    return movies.sort((a, b) => {
      // Prioritize movies matching the user's location
      if (a.isocode.toLowerCase() === location) return -1;
      if (b.isocode.toLowerCase() === location) return 1;
      return 0;
    });
  }
  return movies;
}
