import React from "react";
import { Link } from "react-router-dom";

import WatchMovie from "../movieLink/WatchMovie";
import WatchTrailer from "../movieLink/WatchTrailer";

function SlideItem({ banner, slideId }) {
  const { movieBannerPictureURL, _id } = banner || {};

  return (
    <React.Fragment>
      <div
        className={`carousel-item ${slideId === 0 ? "active" : ""}`}
        data-bs-interval="7000"
        style={{ backgroundImage: `url(${movieBannerPictureURL})` }}
      >
        <Link to={`/moviedetails/${_id}`} className="movie-link"></Link>
        <div className="carousel-caption">
          <div className="container position-relative d-none d-md-block">
            <WatchTrailer movie={banner} />
            <WatchMovie
              movie={banner}
              cssstyle={"btn btn-primary px-5 me-3 float-end text-uppercase"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SlideItem;
