import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectUsers as users } from "../redux/users/users.selector";
import { fetchUsersAsync } from "../redux/users/users.action";

import authBackground from "../assets/images/auth-background.jpg";
import AuthHeader from "../elements/AuthHeader";
import AuthTerms from "../elements/AuthTerms";
import AuthTitleSide from "../elements/AuthTitleSide";
import ModifyPassAuth from "../elements/ModifyPassAuth";
import ExpiredLink from "../elements/ExpiredLink";

const authClass = "auth-page";
const positionClass = "position-relative";

function ModifyPassword({ users, fetchUsersAsync }) {
  let isExpired = false;
  const { userId } = useParams();

  const user = users?.find((u) => u._id === userId);
  isExpired =
    new Date(user?.passwordRecoverExpiry).getTime() > new Date().getTime();

  useEffect(() => {
    fetchUsersAsync();
    document.body.classList.add(authClass);
    document.body.classList.add(positionClass);
    document.body.style.backgroundImage = `url(${authBackground})`;
    return () => {
      document.body.classList.remove(authClass);
      document.body.classList.remove(positionClass);
      document.body.style.backgroundImage = null;
    };
  }, [fetchUsersAsync]);

  return (
    <>
      <AuthHeader />
      <section class="auth-wrap position-absolute">
        <div class="row w-100 justify-content-center mt-5">
          <div class="col-md-8 col-lg-8 col-xl-7">
            <div class="auth-form-wrap p-3 p-md-5">
              <div class="row">
                <AuthTitleSide />
                {isExpired ? (
                  <ModifyPassAuth userId={userId} />
                ) : (
                  <ExpiredLink />
                )}
              </div>
              <AuthTerms />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchUsersAsync: () => dispatch(fetchUsersAsync()),
});

const mapStateToProps = createStructuredSelector({
  users,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPassword);
